import "./Composable.css";
import { useEffect, useState } from "react";
import CmesTabs from "./CmesTabs";
import FactoryAndPanel from "./FactoryAndPanel";
import { useCollection } from "../../hooks/useCollection";
import Process from "../../components/Process";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useApp } from "../../hooks/useApp";
import MockPc from "./MockPc";
import Ipad from "./Ipad";
import { useParams } from "react-router-dom";
import Videoplayer from "../../components/Videoplayer";
import { useAuth } from "../../hooks/useAuth";
import { useAnon } from "../../hooks/useAnon";
import { useSegment } from "../../hooks/useSegment";
import { useTheme } from '@mui/material/styles';

export default function Composable() {
  const vtecAssets = useCollection("vtec_assets").documents;
  const [cmesData, setCmesData] = useState(null);
  const [exampleData, setExampleData] = useState(null);
  const [tab, setTab] = useState("Composable");
  const { setActiveMockPc, activeIpad, setRegister } = useApp();
  const { param } = useParams();
  const { user } = useAuth();
  const { loginAnon } = useAnon();
  const { trackDirectEvent } = useSegment();
  const theme = useTheme();

  //give me some segment auth
  useEffect(() => {
    const cmesAsset = {
      id: "cmesmegafactory",
      Title: "Composable MES",
      Type: "megafactory",
    };
    if (user) {
      trackDirectEvent("asset opened", cmesAsset, "none");
    } else {
      loginAnon("none").then((res) => {
        trackDirectEvent("asset opened", cmesAsset, "none");
      });
    }
  }, []);
  useEffect(() => {
    if (vtecAssets) {
      //pull in the cMES megasuite and mash it with the vTEC assets, replacing anything in the vtec asset with the megasuite override
      const cMES = vtecAssets.filter((vtecAsset) => vtecAsset.id === "cMES")[0]
        .Suites;
      const exampleFlow = vtecAssets.filter(
        (vtecAsset) => vtecAsset.id === (param ? param : "example")
      )[0];

      const cmesSuites = cMES.map((suite) => ({
        ...suite,
        color: theme.palette.info.main,
        Children: suite.Children.map((child) => {
          let realAsset = vtecAssets.filter(
            (vtecAsset) => vtecAsset.id == child.id
          )[0];
          return { ...realAsset, ...child };
        }),
      }));
      setCmesData(cmesSuites);
      setSelectedSuite(cmesSuites[0]);
      // handle the example by replacing grabbing the vtec asset and replacing whatever is there with what is in the example
      const example = {
        ...exampleFlow,
        color: theme.palette.info.main,
        Children: exampleFlow.Children.map((child) => {
          let realAsset = vtecAssets.filter(
            (vtecAsset) => vtecAsset.id === child.id
          )[0];
          return { ...realAsset, ...child };
        }),
      };
      setExampleData(example);
    }
  }, [vtecAssets, param]);

  const [selectedSuite, setSelectedSuite] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const handleMockPc = (url) => {
    setActiveMockPc(url);
  };

  const handleTab = (tab) => {
    setTab(tab);
    setSelectedAsset(null);
  };

  const handleSuiteChange = (newSuiteTitle) => {
    const newSuite = cmesData.filter(
      (suite) => suite.Title == newSuiteTitle
    )[0];
    setSelectedSuite(newSuite);
    setSelectedAsset(null);
  };

  return (
    <div
      className="standardGradient"
      style={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "40px",
        position: "fixed"
      }}
    >
      {selectedSuite && tab !== "Example" && (
        <CmesTabs
          handleSuiteChange={handleSuiteChange}
          selectedSuite={selectedSuite}
          suites={cmesData}
          tab={tab}
        />
      )}
      {tab == "Example" && exampleData.Children.some((child) => child.flow) && (
        <Process
          beaconData={exampleData.Children}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
        />
      )}
      {cmesData && (
        <FactoryAndPanel
          imgHeight={tab == "Example" ? 200 : 200}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
          selectedSuite={tab == "Composable" ? selectedSuite : exampleData}
          nodes={tab == "Example" ? exampleData : null}
        />
      )}
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          right: "20px",
          borderRadius: "100px",
          backgroundColor: theme.palette.info.main,
          cursor: "pointer",
          width: "fit-content",
          fontSize: 14,
          fontFamily: "Messina Sans light",
          height: "fit-content",
          padding: 10,
          color: theme.palette.info.contrastText,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 4,
        }}
        onClick={() => handleMockPc("https://tulip.co/library/")}
      >
        Add More From Library
        <OpenInNewIcon fontSize="small" />
      </div>

      <div
        style={{
          position: "absolute",
          width: "fit-content",
          fontSize: 14,
          fontFamily: "Messina Sans light",
          zIndex: 10,
          height: "fit-content",
          backgroundColor: "transparent",
          borderRadius: "100px",
          cursor: "pointer",
          border: `1px solid ${theme.palette.customColors.highlight}`,
          left: "20px",
          bottom: "20px",
          gap: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            cursor: "pointer",
            color: tab == "Composable" ? theme.palette.secondary.main : theme.palette.customColors.highlight,
            backgroundColor: tab == "Composable" ? theme.palette.customColors.highlight : "transparent",
            padding: 10,
            height: "100%",
          }}
          onClick={() => handleTab("Composable")}
        >
          Composable MES
        </div>
        <div
          style={{
            cursor: "pointer",
            color: tab == "Example" ? theme.palette.secondary.main : theme.palette.customColors.highlight,
            backgroundColor: tab == "Example" ? theme.palette.customColors.highlight : "transparent",
            padding: 10,
            height: "100%",
          }}
          onClick={() => handleTab("Example")}
        >
          {exampleData && exampleData.Title}
        </div>
      </div>
      <MockPc />
      <Ipad />
      <Videoplayer />
    </div>
  );
}
