import { Fragment } from "react";
import {
  Card,
  CardMedia,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import Gobutton from "../../components/Gobutton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import FactoryIcon from "@mui/icons-material/Factory";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";

const style = {
  p: 0,
  width: "100%",
  borderRadius: 2,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "#FFF",
  fontSize: "6px",
};

export default function StepTwo({ asset, setAsset }) {
  function handleChange(event) {
    const newAsset = { ...asset, [event.target.id]: event.target.value };
    setAsset(newAsset);
  }

  const handleNewFeature = () => {
    const newFeatures = [...asset.Features, ""];
    const newAsset = { ...asset, Features: newFeatures };
    setAsset(newAsset);
  };

  return (
    <Grid container sx={{ flexDirection: "row", gap: 4 }}>
      <Grid item xs={12} sm={6} md={7} sx={{ justifyContent: "center", alignItems: "center" }}>
        <Grid
          item
          xs
          sx={{ backgroundColor: "background.default" }}
          marginY={"auto"}
          borderRadius={"0px 8px 8px 8px"}
          padding={5}
        >
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Typography color={"customColors.default"} variant="h8">
                {asset.Title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} alignItems={"center"}>
              <Card sx={{ boxShadow: "none", backgroundColor: "transparent" }}>
                <CardMedia
                  component="img"
                  image={asset.img}
                  style={{
                    boxShadow: "none",
                    maxWidth: "100%", // Set width to 100% to fill the entire width of the Card
                    maxHeight: "80vh",
                    objectFit: "cover", // Maintain aspect ratio while covering the entire space
                    objectPosition: "center", // Center the image within the CardMedia
                  }}
                />
              </Card>
            </Grid>
            <Grid item xs>
              <Typography fontSize={"small"} color={"customColors.default"}>
                Key Features
              </Typography>
              {asset.Features && asset.Features[0] != "" && (
                <List aria-label="mailbox folders" sx={style}>
                  {asset.Features.map((feature, index) => (
                    <Fragment key={index}>
                      <ListItem>
                        <ListItemText
                          sx={{ color: "customColors.defaultLight" }}
                          primary={feature}
                        />
                      </ListItem>
                      {index < asset.Features.length - 1 && (
                        <Divider component="li" />
                      )}
                    </Fragment>
                  ))}
                </List>
              )}
            </Grid>
            <Grid
              container
              justifyContent={"right"}
              alignItems={"bottom"}
              gap={2}
            >
              {asset.appUrl && (
                <Gobutton
                  text={"Open the App"}
                  startIcon={<ExitToAppIcon />}
                  url={{ type: "beacon", url: asset.appUrl }}
                />
              )}
              {asset.editorUrl && (
                <Gobutton
                  text={"Open the Editor"}
                  startIcon={<LaptopMacIcon />}
                  url={{ type: "beacon", url: asset.editorUrl }}
                />
              )}
              {asset.contentUrl && (
                <Gobutton
                  text={"More info"}
                  startIcon={<InfoIcon />}
                  url={{ type: "content", url: asset.contentUrl }}
                />
              )}
              {asset.video && (
                <Gobutton
                  text={"Play the Video"}
                  startIcon={<InfoIcon />}
                  url={{ type: "video", url: asset.video }}
                />
              )}
              {asset.Type == "factory" && (
                <Gobutton
                  text={"Explore the Factory"}
                  startIcon={<FactoryIcon />}
                  url={"factory"}
                />
              )}
              {asset.Type == "megafactory" && (
                <Gobutton
                  text={"Explore the Factory"}
                  startIcon={<FactoryIcon />}
                  url={"megafactory"}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <Stack direction={"column"} gap={4}>
          <TextField
            id="img"
            label="Image"
            fullWidth
            value={asset.img}
            onChange={(event) => {
              handleChange(event);
            }}
          />
          <TextField
            id="desc"
            label="Verbose Description"
            fullWidth
            value={asset.desc}
            onChange={(event) => {
              handleChange(event);
            }}
          />

          <Stack direction={"column"} gap={2} width={"100%"}>
            <Typography>Features</Typography>
            {asset.Features.length > 0 &&
              asset.Features.map((feature, thisIndex) => (
                <Stack direction={"row"}>
                  <IconButton
                    aria-label="delete"
                    xs
                    onClick={() => {
                      const newFeatures = asset.Features.filter(
                        (item, index) => index !== thisIndex
                      );
                      setAsset({ ...asset, Features: newFeatures });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <TextField
                    key={`feature-${thisIndex}`}
                    size="small"
                    sx={{ width: "100%" }}
                    id={`feature-${thisIndex}`}
                    value={feature}
                    onChange={(e) => {
                      const updatedItems = [...asset.Features];
                      updatedItems[thisIndex] = e.target.value;
                      setAsset({ ...asset, Features: updatedItems });
                    }}
                  />
                </Stack>
              ))}
            <Grid container>
              <Grid item xs>
                <IconButton
                  aria-label="delete"
                  xs
                  onClick={(event) => {
                    handleNewFeature(event);
                  }}
                >
                  <AddCircleIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}
