import { useState, useEffect } from "react";
import { functions } from "../firebase/config";
import { httpsCallable } from "firebase/functions";
import { useAuth } from "./useAuth";

export const useSalesforce = () => {
  const [opps, setOpps] = useState({ data: null });
  const { user } = useAuth();

  useEffect(() => {
    const getOpps = async () => {
      const res = await httpsCallable(
        functions,
        "getopps"
      )({ partner: user.partnerAccount });
      console.log("use salesforce loaded useeffect");
      setOpps(res);
    };
    getOpps();
  }, []);

  return {
    opps,
  };
};
