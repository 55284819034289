import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useSalesforce } from "../hooks/useSalesforce";
import { useAuth } from "../hooks/useAuth";
import { useApp } from "../hooks/useApp";

export default function Oppselect() {
  const { authIsReady, user } = useAuth();
  const { opps } = useSalesforce();
  const { selectedOpp, setSelectedOpp } = useApp();

  const loading = opps.length === 0;

  // Sorting the options alphabetically by opportunity_owner_name__c
  const sortedOptions = opps.data
    ? [...opps.data].sort((a, b) => {
        // Place the user.name at the top
        if (a.opportunity_owner_name__c === user.displayName) return -1;
        if (b.opportunity_owner_name__c === user.displayName) return 1;

        // Sort the remaining options alphabetically
        return a.opportunity_owner_name__c.localeCompare(
          b.opportunity_owner_name__c
        );
      })
    : []; //[{ name: "loading" , opportunity_owner_name__c:"loading"}];
  return (
    <Autocomplete
      id="tags-outlined"
      options={sortedOptions}
      value={selectedOpp}
      noOptionsText={"Loading..."}
      sx={{ backgroundColor: "#FFF" }}
      loading={loading}
      onChange={(event, newValue) => {
        setSelectedOpp(newValue);
      }}
      size="small"
      getOptionLabel={(option) => option.name}
      groupBy={(option) => option.opportunity_owner_name__c}
      renderInput={(params) => (
        <TextField {...params} placeholder="Select Opportunity" />
      )}
    />
  );
}
