export default {
  // Converts a hex color code to an RGB object
  hexToRgb(hex) {
    let cleanedHex = hex.startsWith("#") ? hex.slice(1) : hex;
    if (cleanedHex.length === 3) {
      cleanedHex = [...cleanedHex].map((char) => char + char).join("");
    }
    const num = parseInt(cleanedHex, 16);
    return {
      r: (num >> 16) & 255,
      g: (num >> 8) & 255,
      b: num & 255,
    };
  },

  // Calculates the luminance of an RGB color
  getLuminance({ r, g, b }) {
    const toLinear = (c) => {
      const sRGB = c / 255;
      return sRGB <= 0.03928 ? sRGB / 12.92 : ((sRGB + 0.055) / 1.055) ** 2.4;
    };
    const [red, green, blue] = [r, g, b].map(toLinear);
    return 0.2126 * red + 0.7152 * green + 0.0722 * blue;
  },

  // Determines if a color is light or dark based on its luminance
  isLightOrDark(hex) {
    const rgb = this.hexToRgb(hex);
    const luminance = this.getLuminance(rgb); 
    return luminance > 0.5 ? "light" : "dark"; 
  },

  // Adjusts the brightness of a hex color by a specified amount
  adjustBrightness(hex, amount) {
    let cleanedHex = hex.startsWith("#") ? hex.slice(1) : hex;
    const num = parseInt(cleanedHex, 16);
    const clamp = (value) => Math.min(255, Math.max(0, value));
    const r = clamp((num >> 16) + amount);
    const g = clamp(((num >> 8) & 0x00ff) + amount);
    const b = clamp((num & 0x0000ff) + amount);
    const newColor = (r << 16) | (g << 8) | b;
    return `#${newColor.toString(16).padStart(6, "0")}`;
  },

  // Returns both a lighter and a darker version of a hex color
  getLighterAndDarker(hex, amount = 20) {
    return {
      lighter: this.adjustBrightness(hex, amount),
      darker: this.adjustBrightness(hex, -amount),
    };
  },
};
