import { useReducer, useEffect, useState } from "react";
import { db } from "../firebase/config";
import { doc, setDoc, onSnapshot } from "firebase/firestore";
import { useAuth } from "./useAuth";

let initialState = {
  document: null,
  isPending: false,
  error: null,
  success: null,
};

const firestoreReducer = (state, action) => {
  switch (action.type) {
    case "IS_PENDING":
      return { document: null, success: null, error: null, isPending: true };
    case "ADDED_DOCUMENT":
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        document: null,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const useAdmin = () => {
  const { user } = useAuth();
  const [response, dispatch] = useReducer(firestoreReducer, initialState);
  const [isCancelled, setIsCancelled] = useState(false);
  const [myAdminRecord, setMyAdminRecord] = useState(null);
  const [error, setError] = useState(null);
  const [mydocPending, setMydocPending] = useState(false);

  // const dispatchIfNotCancelled = (action) => {
  //   if (!isCancelled) {
  //     dispatch(action);
  //     setMydocPending(true);
  //   }
  // };

  //fires once and whenever the collection changes
  useEffect(() => {
    setMydocPending(true);
    //set up a real time listener to a firestore collection
    let ref = doc(db, "user_admin", user && user.uid ? user.uid : "empty");
    const unsubscribe = onSnapshot(
      ref,
      (snapshot) => {
        if (snapshot.exists()) {
          console.log("exists");
          setMyAdminRecord(snapshot.data());
        } else {
          console.log("doc doesnt exist");
          if (user && user.uid) {
            setDoc(
              doc(db, "user_admin", user.uid),
              { admin: false },
              { merge: true }
            );
          }
          setMyAdminRecord(null);
        }
        setMydocPending(false);
        setError(null);
      },
      (error) => {
        console.log(error);
        setMydocPending(false);
        setError("could not fetch the doc");
      }
    );
    //unsub on unmount
    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    return () => setIsCancelled(true);
  }, [myAdminRecord]);

  const updateAdminRecord = (param) => {
    setDoc(doc(db, "user_admin", user.uid), { [param]: true }, { merge: true });
  };

  return { myAdminRecord, updateAdminRecord };
};
