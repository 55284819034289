import { Grid, Modal, Stack, TextField, Typography } from "@mui/material";
import { useApp } from "../hooks/useApp";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useAdmin } from "../hooks/useAdmin";
import { useState } from "react";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { useStations } from "../hooks/useStations";

export default function RegisterModal() {
  const { register, setRegister, setActiveIpad } = useApp();
  const { myAdminRecord, updateAdminRecord } = useAdmin();
  const [links, setLinks] = useState();
  const { createStations, isPending } = useStations();

  const instances = [
    {
      instance: "acmecorp",
      key: "ACMECORP",
      desc: "Original Motor vTEC",
      workspace: "DEFAULT",
      stationGroup: "bGHx2savWbbkeGNMd",
    },
    {
      instance: "composability",
      key: "COMPOSABILITY",
      desc: "cMES",
      workspace: "DEFAULT",
      stationGroup: "B3gmHBuoyjXNJ6Dvw",
    },
    {
      instance: "life-sciences-vtec",
      key: "LIFE_SCIENCES_VTEC",
      desc: "Pharma vTEC",
      workspace: "DEFAULT",
      stationGroup: "AR8sLQmdshRwq9ibn",
    },
  ];

  const handleReset = (row) => {
    const newKey = `${row.key}_${row.type}`;
    setLinks((prev) => {
      return {
        ...prev,
        [newKey]: { loading: true, url: false },
      };
    });

    const setLinksRef = setLinks;

    (async () => {
      try {
        console.log(row.key, row.stationGroup, row.workspace, row.key);
        const response = await createStations(
          row.instance,
          row.stationGroup,
          row.workspace,
          row.key
        ); // Adjust arguments as needed
        console.log(response);
        // Update the links state to set loading to false for the newKey
        setLinksRef((prev) => ({
          ...prev,
          [newKey]: {
            ...prev[newKey],
            loading: false,
            url: response.data.data.registration.link[row.type],
          },
        }));
      } catch (error) {
        console.error("Failed to create stations:", error);
        // Handle any errors, perhaps by also updating the state to reflect the error
        setLinksRef((prev) => ({
          ...prev,
          [newKey]: { ...prev[newKey], loading: false, error: true },
        }));
      }
    })();
  };

  const handleLink = (row) => {
    const gotoUrl = links[`${row.key}_${row.type}`]["url"]
      ? links[`${row.key}_${row.type}`]["url"]
      : null;
    console.log(gotoUrl);
    try {
      updateAdminRecord(`${row.key}_${row.type}`);
    } catch {
      console.log("no user");
    }
    if (row.type == "browser") {
      setActiveIpad(gotoUrl);
    } else {
      window.open(gotoUrl, "_blank");
    }
    setLinks((prev) => {
      const newKey = `${row.key}_${row.type}`;
      return {
        ...prev,
        [newKey]: { loading: false, url: null },
      };
    });
  };

  return (
    <Modal
      open={register !== null}
      onClose={() => setRegister(null)}
      disableAutoFocus
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Grid item xs={11} sm={10} lg={7} backgroundColor={"white"} borderRadius={4} className="p-6 lg:p-12">
        <Typography variant="h6">Register Devices</Typography>
        <p />
        <Typography>
          Opening the App Editor or Playing an App requires that you have a
          Tulip User, Station, and Device. Without this, you will be required to
          create one of each and this may require a Tulip Admin. You can use
          this window to quickly register your browser or player.
        </Typography>
        <p />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Tulip Instance</TableCell>
                <TableCell align="left">Description</TableCell>
                <TableCell align="center">Player Device</TableCell>
                <TableCell align="center">Browser Device</TableCell>
              </TableRow>
            </TableHead>
            {instances.map((row) => (
              <TableRow
                key={row.key}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {" "}
                  {row.instance}
                </TableCell>
                <TableCell align="left">{row.desc}</TableCell>
                <TableCell align="center">
                  {myAdminRecord && myAdminRecord[`${row.key}_player`] ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                  {links &&
                  links[`${row.key}_player`] &&
                  links[`${row.key}_player`]["url"] ? (
                    <InsertLinkIcon
                      style={{ cursor: "pointer", color: "blue" }}
                      onClick={() => handleLink({ ...row, type: "player" })}
                    />
                  ) : links &&
                    links[`${row.key}_player`] &&
                    links[`${row.key}_player`]["loading"] ? (
                    <HourglassEmptyIcon />
                  ) : (
                    <RestartAltIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => handleReset({ ...row, type: "player" })}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {myAdminRecord && myAdminRecord[`${row.key}_browser`] ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                  {links &&
                  links[`${row.key}_browser`] &&
                  links[`${row.key}_browser`]["url"] ? (
                    <InsertLinkIcon
                      style={{ cursor: "pointer", color: "blue" }}
                      onClick={() => handleLink({ ...row, type: "browser" })}
                    />
                  ) : links &&
                    links[`${row.key}_browser`] &&
                    links[`${row.key}_browser`]["loading"] ? (
                    <HourglassEmptyIcon />
                  ) : (
                    <RestartAltIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => handleReset({ ...row, type: "browser" })}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </Grid>
    </Modal>
  );
}
