import { Box, Modal } from "@mui/material";
import { useApp } from "../../hooks/useApp";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from '@mui/material/styles';

export default function Ipad() {
  const { activeIpad, setActiveIpad } = useApp();
  const theme = useTheme();
  const handleClose = () => {
    setActiveIpad(null);
  };
  return (
    <React.Fragment>
      <Modal
        open={activeIpad}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%", // Adjust the width as needed
            height: "fit-content",
            bgcolor: "white",
            padding: 5,
            boxShadow: 0,
            borderRadius: 8,
          }}
        >
          {activeIpad && (
            <div
              style={{
                paddingBottom: 20,
                fontFamily: "Messina Sans",
                fontSize: 20,
                color: "#121C2E",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>{activeIpad.Title}</div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon />
              </div>
            </div>
          )}
          <div
            style={{
              position: "relative",
              width: "100%",
              height: 0,
              paddingBottom: "56.25%",
            }}
          >
            <img
              src="https://tulip.widen.net/content/t3gemd6t3s/webp/Tablet%20Graphic.webp"
              style={{
                position: "absolute",
                top: 0,
                left: "1%",
                width: "98%",
                height: "100%",
                backgroundColor: "transparent",
              }}
            />
            {true && (
              <iframe
                src={activeIpad && activeIpad.browserUrl}
                outline="none"
                frameborder="0"
                allowtransparency="true"
                scrolling="no"
                style={{
                  outline: "none",
                  position: "absolute",
                  borderRadius: "40px",
                  top: "2.5%",
                  left: "2.5%",
                  width: "95%",
                  height: "95%",
                  margin: 0,
                }}
              ></iframe>
            )}
          </div>
          {activeIpad && activeIpad.Heading && (
            <div style={{ paddingTop: 20, color: theme.palette.secondary.main }}>
              {activeIpad.Heading}
            </div>
          )}
        </Box>
      </Modal>
    </React.Fragment>
  );
}
