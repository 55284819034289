import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useDocs } from '../../hooks/useDocs';
import { useAuth } from '../../hooks/useAuth';
import { useState } from 'react';
import { Stack, TextField } from '@mui/material';
import { Widgets } from '@mui/icons-material';
import { useImageUpload } from './useImageUpload';
import Grid from '@mui/material/Grid';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; 
import { app } from '../../firebase/config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function SeedModal({pastedData, clearPastedData}) {
    const {addSeed} = useDocs('seeds');
    const {user} = useAuth();
    const [meta, setMeta] = useState({title:null,desc:null, imgurl:null});
    const [isTulip, setIsTulip] = useState(false)
    const [seedData, setSeedData] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null);

  const handleAdd = async () => {

    const filename = `${user.id}-${generateRandomFileName()}`
    try {
      const storage = getStorage(app);
      const storageRef = ref(storage, `images/${filename}`);
  
      // Get the file data from the fileURL
      const response = await fetch(meta.imgurl);
      const blob = await response.blob();
  
      const uploadTask = uploadBytesResumable(storageRef, blob);
  
      await uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Optional: Track upload progress
        },
        (error) => {
          console.error('Error uploading image:', error);
        },
        () => {
          // Upload complete
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log(meta)
            addSeed(pastedData, user, { ...meta, imgurl: downloadURL });
            handleClose();
          });
        }
      );
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };


    const handleInput = (event) => {
        const { value, id } = event.target;
        setMeta((prev) => ({
            ...prev,
            [id]: value
        }));
    };

    
    const handleClose = () => {
        setMeta({title:null,desc:null});
        setSeedData(null);
        clearPastedData();
    }

    React.useEffect(() => {
        
        if (pastedData?.hasOwnProperty("application/x.tulip.clipboard.step-editor+json")) {
          setIsTulip(true);
          setSeedData(pastedData);
        }
        if (pastedData && pastedData['Files'] && pastedData['Files'].fileURL) {
            setMeta((prev) => ({
                ...prev,
                imgurl: pastedData['Files'].fileURL
            }));
        }
      }, [pastedData]);
    
  return (
    <div>
      <Modal
        open={pastedData}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Grid container justifyContent={'space-around'}>
            <Grid item xs={5} justifyContent={'center'} alignItems={'center'}>
                {meta.imgurl ? 
                <img
                src={meta.imgurl}
                alt="Pasted Image"
                style={{ width: '100%', height: 'auto' }}
            /> : 'Paste an image'}
            </Grid>
            <Grid item xs={5}>
                <Stack alignItems={'flex-start'} gap={2}>
                    
            <Typography id="modal-modal-title" variant="h6" component="h2" mb={1}>
                Create new Seed
            </Typography>
            <Typography fontSize={12}>Details: {isTulip?'Valid Tulip Seed':'Invalid Tulip Seed'}</Typography>
            {isTulip &&
            <Stack>
            <Typography fontSize={10}>Widgets: {seedData?.['application/x.tulip.clipboard.step-editor+json']?.widgets?.length || 0}</Typography>
            <Typography fontSize={10}>Variables: {seedData?.['application/x.tulip.clipboard.step-editor+json']?.variables?.length || 0}</Typography>
            <Typography fontSize={10}>dataModelSlots: {seedData?.['application/x.tulip.clipboard.step-editor+json']?.dataModelSlots?.length || 0}</Typography>
            </Stack>}
            <TextField
                id="title"
                label="Title"
                value={meta.title || ""}
                onChange={handleInput}
                size='small'
                fullWidth
                InputLabelProps={{
                shrink: true,
                }}
            />
            <TextField
                id="desc"
                label="Description"
                value={meta.desc || ""}
                onChange={handleInput}
                size='small'
                fullWidth
                multiline
                InputLabelProps={{
                shrink: true,
                }}
            />
            <Button sx={{mt:4}} variant='outlined' disabled={!meta.title||!meta.desc||!meta.imgurl} onClick={handleAdd}>Add seed</Button>
            
            </Stack>
          </Grid>
        </Grid>
        </Box>
      </Modal>
    </div>
  );
}
// Helper function to generate a random file name
function generateRandomFileName() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomName = "";
    for (let i = 0; i < 10; i++) {
      randomName += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return randomName;
  }