import { Grid } from "@mui/material";
import "./Process.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Fragment } from "react";

export default function Process({
  beaconData,
  selectedAsset,
  setSelectedAsset,
}) {
  const filteredBeacons = beaconData.filter((beacon) => beacon.flow === true);

  return (
    <Grid container paddingBottom={0} paddingTop={"13px"}>
      <Grid item sm={0} md />
      <Grid
        item
        sm
        md={"auto"}
        className="process-container"
      >
        <Grid
        className="process-container-inner"
          container
          justifyContent={"center"}
          gap={2}
          alignItems={"center"}
          paddingX={2}
          paddingY={1}
          flexWrap={"wrap"}
          maxWidth={"95vw"}
        >
          {filteredBeacons.map((beacon, index, array) => (
            <Fragment key={index}>
              <Grid
                item
                className={`process-title${
                  JSON.stringify(selectedAsset) === JSON.stringify(beacon)
                    ? " underlined"
                    : ""
                }`}
                onClick={() => setSelectedAsset(beacon)}
                sx={{ color: "customColors.white" }}
              >
                {beacon.Title}
              </Grid>
              {index !== array.length - 1 && (
                <Grid item>
                  <ArrowForwardIcon sx={{ color: "customColors.white" }} />
                </Grid>
              )}
            </Fragment>
          ))}
        </Grid>
      </Grid>
      <Grid item sm={0} md />
    </Grid>
  );
}
