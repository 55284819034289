import { useState } from "react";
import { useAuth } from "./useAuth";
import { auth } from "../firebase/config";
import { signOut } from "firebase/auth";
import { useApp } from "./useApp";

const defaultTheme = {
  primaryColor: "#00263e",
  bgColor: "#E7E6DF",
  highlight: "#F2EEA1",
};
export const useLogout = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatchUser } = useAuth();
  const { setPartnerTheme } = useApp();

  const logout = async () => {
    setError(null);
    setIsPending(true);
    //sign the user out
    try {
      await signOut(auth);
      //dispatch logout action
      dispatchUser({ type: "LOGOUT" });
      setIsPending(false);
      setError(null);
      setPartnerTheme(defaultTheme);
      localStorage.removeItem("theme");
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsPending(false);
    }
  };
  return { logout, error, isPending };
};
