import React from 'react';
import { getSmoothStepPath } from 'reactflow';

const UnsEdge = ({ id, sourceX, sourceY, targetX, targetY, label, data }) => {

  const { topic, hotTopic, targetPosition, sourcePosition } = data; // Access custom properties from data
  // Get the smooth step path using the provided coordinates
  console.log({ id, sourceX, sourceY, targetX, targetY, label, data })
  const [pathString] = getSmoothStepPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition: sourcePosition, // Optional: adjust as needed
    targetPosition: targetPosition, // Optional: adjust as needed
    // Additional configuration options if necessary
  });

  // Calculate the midpoint for positioning the text and background
  const midX = (sourceX + 2);
  const midY = (sourceY );
  const isHighlighted = 
  (typeof hotTopic === 'string' && hotTopic.includes(topic)) || 
  (Array.isArray(topic) && topic.some(t => typeof hotTopic === 'string' && hotTopic.includes(t)));

  return (
    <>
      <path id={id} style={{ stroke: isHighlighted ? '#F2EEA1': '#fff' , strokeWidth: isHighlighted ? 2 : 1, fill:'none' }} d={pathString} />

      {/* Background rectangle for text */}
      <foreignObject
        width={100} // Adjust the width as necessary
        height={30} // Adjust the height as necessary
        x={midX} // Center the text horizontally
        y={midY} // Center the text vertically
      >
        <div >
          <div style={{ color: 'white', fontSize: '10px' }}>
            {label}
          </div>
        </div>
      </foreignObject>
    </>
  );
};

export default UnsEdge;
