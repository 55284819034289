import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import { useApp } from "../hooks/useApp";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { styled, useTheme } from "@mui/material/styles";
import { purple } from "@mui/material/colors";
import { TextField } from "@mui/material";
import { useDocs } from "../hooks/useDocs";
import { useEffect, useRef, useState } from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Bookmarktable from "./Bookmarktable";
import EmailIcon from "@mui/icons-material/Email";
import { useAi } from "../hooks/useAi";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useSegment } from "../hooks/useSegment";
import { useSlack } from "../hooks/useSlack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSendgrid } from "../hooks/useSendgrid"; 

export default function Oppdrawer() {
  const { ReadDocument, setOpportunity, setOpportunityBookmarks } =
    useDocs("opportunities");
  const [localNotes, setLocalNotes] = useState(null);
  const { oppyState, setOppyState, selectedOpp, setSelectedOpp } = useApp();
  const timerRef = useRef();
  const { mydoc } = ReadDocument("opportunities", selectedOpp.id);
  const { runAI } = useAi();
  const [pending, setPending] = useState(false);
  const { trackVOC } = useSegment();
  const { sendSlack } = useSlack();
  const [open, setOpen] = useState(false);
  const [aiMessage, setAiMessage] = useState(null);
  const { prepData } = useSendgrid();
  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmail = () => {
    prepData();
    setOppyState((prev) => !prev);
  };
  const handleAI = async () => {
    try {
      // Simulating an asynchronous operation
      setOpportunityBookmarks(selectedOpp.id, null);
      setPending(true);
      const result = await runAI(localNotes);
      setPending(false);
      setOpportunityBookmarks(selectedOpp.id, result.data["id_array"]);
      trackVOC(localNotes);
      sendSlack(localNotes);
      setAiMessage(result.data["context"]);
      setOpen(true);
    } catch (error) {
      setPending(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log(
      `setting notes to ${mydoc && mydoc.notes ? mydoc.notes : null}`
    );
    setLocalNotes(mydoc && mydoc.notes ? mydoc.notes : null);
  }, [mydoc]); // Only run the effect when vtecAssets changes

  const handleDebounce = (value) => {
    setOpportunity(selectedOpp.id, value);
  };

  const handleChange = (event) => {
    setLocalNotes(event.target.value);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = window.setTimeout(() => {
      handleDebounce(event.target.value);
    }, 1000);
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.info.main,
    },
  }));

  return (
    <Drawer
      anchor={"right"}
      open={oppyState}
      PaperProps={{
        sx: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      onClose={() => setOppyState((prev) => !prev)}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">AI Assistant</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {aiMessage}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Grid container width={500} height={"100%"} justifyContent={"right"}>
        <Grid item>
          <svg
            width={35}
            id="topper-notch"
            viewBox="0 0 42 152"
            overflow={"hidden"}
          >
            <path
              d="M41 0H40H1H0V96.5C0 101.6 2 106.3 5.6 109.9L35 139.4C38.2 142.6 40 146.9 40 151.4H41H42V0H41Z"
              fill={theme.palette.background.default}
            ></path>
            <path
              d="M35.7 138.7L6.3 109.3C2.9 105.9 1 101.3 1 96.6V0H0V96.5C0 101.6 2 106.3 5.6 109.9L35 139.4C38.2 142.6 40 146.9 40 151.4H41C41 146.7 39.1 142.1 35.7 138.7Z"
              fill={theme.palette.background.default}
            ></path>
          </svg>
        </Grid>
        <Grid
          item
          container
          backgroundColor={"background.default"}
          xs
          padding={4}
          justifyContent={"space-between"}
          flexDirection={"column"}
        >
          <Stack gap={2}>
            <Typography color={"customColors.default"} variant="h6">
              {selectedOpp["name"]}
            </Typography>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                SDR Notes
              </AccordionSummary>
              <AccordionDetails>{selectedOpp["sdr_notes__c"]}</AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Pain Points
              </AccordionSummary>
              <AccordionDetails>
                {selectedOpp["identify_pain__c"]}
              </AccordionDetails>
            </Accordion>

            <Typography color={"rgba(1,1,1,.6)"} variant="h10">
              Problems to Solve
            </Typography>
            <TextField
              multiline
              onChange={handleChange}
              value={localNotes}
              rows={8}
              height={"500px"}
              variant="filled"
            />
            <ColorButton startIcon={<AutoAwesomeIcon />} onClick={handleAI}>
              AI Demo Search
            </ColorButton>
            <Typography color={"rgba(1,1,1,.6)"} variant="h10">
              Favorite Content
            </Typography>

            {pending && (
              <Box justifyContent={"center"} sx={{ display: "flex" }}>
                <CircularProgress alignSelf="center" />
              </Box>
            )}
            <Bookmarktable />
            <ColorButton startIcon={<EmailIcon />} onClick={handleEmail}>
              Send yourself bookmarks
            </ColorButton>
          </Stack>
          <Stack paddingTop={2}>
            <ColorButton
              onClick={() => {
                setSelectedOpp(null);
                setOppyState(false);
              }}
            >
              choose New opportunity
            </ColorButton>
          </Stack>
        </Grid>
      </Grid>
    </Drawer>
  );
}
