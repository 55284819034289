import { useState } from "react";
import { useApp } from "./useApp";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/config";

export const useAi = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { vtecAssets } = useApp();

  const storageTest = async () => {
    setError(null);
    setIsPending(true);

    try {
      //signup user
      console.log("running please");
      const newjson = vtecAssets.map((asset) => ({
        id: asset.id,
        Title: asset.Title,
        desc: asset.desc,
      }));
      const res = await httpsCallable(functions, "createEmbeddings")();
      if (!res) {
        throw new Error("could not complete signup");
      }

      console.log(res);
      setIsPending(false);
      setError(null);
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsPending(false);
    }
  };

  const runAI = async (problem) => {
    setError(null);
    setIsPending(true);

    try {
      //signup user
      console.log("running please");
      const newjson = vtecAssets.map((asset) => ({
        id: asset.id,
        Title: asset.Title,
        desc: asset.desc,
      }));
      const res = await httpsCallable(functions, "getAI")({ problem: problem });
      if (!res) {
        throw new Error("could not complete signup");
      }

      console.log(res);
      setIsPending(false);
      setError(null);

      return res;
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsPending(false);

      return null;
    }
  };

  return { error, isPending, storageTest, runAI };
};
