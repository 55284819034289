import { useState } from "react";
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import { auth } from "../firebase/config";
import { useAuth } from "./useAuth";
import { useDocs } from "./useDocs";
import { useSlack } from "./useSlack";

export const useSignup = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatchUser } = useAuth();
  const { setUserDeets } = useDocs("partners");
  const { genericSlack } = useSlack();

  const signup = async (email, password, displayName) => {
    setError(null);
    setIsPending(true);

    try {
      //signup user
      const res = await createUserWithEmailAndPassword(auth, email, password);
      if (!res) {
        throw new Error("could not complete signup");
      }
      await sendEmailVerification(res.user);
      await updateProfile(res.user, { displayName: displayName });
      setUserDeets(res.user.uid, email, res.user.emailVerified);
      dispatchUser({ type: "LOGIN", payload: res.user });
      genericSlack("Partner has requested access");
      setIsPending(false);
      setError(null);
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsPending(false);
    }
  };

  return { error, isPending, signup };
};
