import Grid from "@mui/material/Grid";
import {
  Box,
  Stack,
  Typography,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { TextField } from "@mui/material";
import { useCollection } from "../../hooks/useCollection";
import { useDocs } from "../../hooks/useDocs";
import Navbar from "../../components/Navbar";
import { useApp } from "../../hooks/useApp";
import { useAuth } from "../../hooks/useAuth";
import { emitToast } from "../../common/toastEmitter";
import SearchIcon from "@mui/icons-material/Search";

export default function PartnerAdmin() {
  const { documents } = useCollection("partners");
  const [selectedRow, setSelectedRow] = useState(null);
  const [filterState, setFilterState] = useState("all");
  const [searchText, setSearchText] = useState("");
  const { documents: admins } = useCollection("user_admin");
  const { deletePartner, getUserPrivateId, updateMultipleDocuments } =
    useDocs("partners");
  const { user } = useAuth();
  const { setPartnerTheme } = useApp();

  const columns = [
    { field: "id", headerName: "ID", width: 180 },
    { field: "email", headerName: "Email", width: 260 },
    { field: "approved", headerName: "Approved", width: 120 },
    { field: "partnerAccount", headerName: "Account", width: 180 },
    {field: "emailVerified", headerName: "Verified", width: 80}
  ];

  const filteredPartners = useMemo(() => {
    if (!documents) return [{ id: "loading" }];

    const basicFilter = documents.filter(
      (item) => item.email && !item.email.includes("@tulip.co")
    );
    if (searchText) {
      return basicFilter.filter((item) =>
        item.email.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (filterState === "notApproved") {
      return basicFilter.filter((item) => item.approved === false);
    }

    if (filterState === "approved") {
      return basicFilter.filter(
        (item) => item.approved === true || item.approved === "true"
      );
    }

    return basicFilter;
  }, [documents, filterState, searchText]);

  const partners = useMemo(() => {
    return filteredPartners.map((partner) => {
      // Check if admins array is loaded and find the matching admin based on the id
      const matchingAdmin = admins?.find((admin) => admin.id === partner.id);

      // Construct the partner object
      const partnerObj = {
        id: partner.id || "loading",
        email: partner.email || "needs lookup",
        approved: partner.approved || false,
        partnerAccount: partner.partnerAccount || "sf account id",
        privateId: partner.privateId || "",
        emailVerified: partner?.emailVerified || false
      };

      // Add the admin property only if a matching admin is found
      if (matchingAdmin) {
        partnerObj.admin = matchingAdmin.admin; // Replace 'adminProperty' with the actual property name from the admin object
      }

      return partnerObj;
    });
  }, [filteredPartners, admins]);

  const handleCellClick = (params) => {
    const selectedPartner = partners.find(
      (partner) => partner.id === params.row.id
    );
    setSelectedRow(selectedPartner || null);
  };

  const handleChange = (key, value) => {
    setSelectedRow((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSave = async () => {
    try {
      if (!selectedRow) return;

      const { id,admin, ...rest } = selectedRow;
      // await updateDocument(id, rest, "partners");

      const updates = [
        {
          collection: "partners",
          id: id,
          data: rest,
        },
        {
          collection: "user_admin",
          id: id,
          data: {
           admin:admin
          },
        },
      ];
      await updateMultipleDocuments(updates);

      emitToast("Partner updated successfully!", "success");

      if (user.uid !== selectedRow.id) return;
      if (user.privateId === selectedRow.privateId) return;

      user.privateId = selectedRow.privateId;
      const userData = await getUserPrivateId(selectedRow.privateId);

      const theme = userData?.theme || {
        primaryColor: "#00263e",
        bgColor: "#E7E6DF",
        highlight: "#F2EEA1",
      };

      if (userData?.theme) {
        localStorage.setItem("theme", JSON.stringify(userData.theme));
      } else {
        localStorage.removeItem("theme");
      }

      setPartnerTheme(theme);
    } catch (err) {
      console.error("Error updating account:", err);
      emitToast("Error while updating partner", "error");
    }
  };

  const handleDelete = () => {
    if (selectedRow) {
      deletePartner(selectedRow);
      setSelectedRow(null);
    }
  };

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) {
      setFilterState(newFilter);
    }
  };

  return (
    <>
      <Navbar sx={{ backgroundColor: "grey", height: "20%" }} />
      <Stack direction="row" paddingLeft={2} paddingTop={2}>
        <TextField
          size="small"
          label="Quick Search"
          sx={{ bgcolor: "background.default" }}
          value={searchText}
          className="!mr-1 max-w-[60%] md:max-w-[30%]"
          onChange={(e) => setSearchText(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <ToggleButtonGroup
          value={filterState}
          exclusive
          onChange={handleFilterChange}
        >
          <ToggleButton value="all" size="small" color="primary">
            Show All
          </ToggleButton>
          <ToggleButton value="notApproved" size="small" color="primary">
            Show Not Approved
          </ToggleButton>
          <ToggleButton value="approved" size="small" color="primary">
            Show Approved
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Grid
        container
        justifyContent="center"
        height="100%"
        alignItems="start"
        gap={2}
        paddingY={2}
        paddingX={2}
      >
        <Grid
          item
          xs={12}
          lg={7}
          backgroundColor="white"
          padding={2}
          flexDirection="column"
          sx={{
            overflowY: "auto",
            height: "calc(100vh - 100px)",
            padding: "0 10px 10px",
          }}
        >
          <Typography marginY={2}>Partners</Typography>
          <DataGrid
            rows={partners}
            columns={columns}
            onCellClick={handleCellClick}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[20, 40]}
            checkboxSelection={false}
          />
        </Grid>
        <Grid item xs backgroundColor="white" padding={2}>
          <Stack>
            <Typography marginY={2}>Selected Asset</Typography>
            <Box
              sx={{
                overflowY: "auto",
                height: "calc(100vh - 226px)",
                padding: "0 10px 10px",
              }}
            >
              {selectedRow &&
                Object.entries(selectedRow).map(([key, value]) =>
                  Array.isArray(value) ? null : (key === "admin" || key === "emailVerified") ? (
                    <FormGroup key={key}>
                    <FormControlLabel control={<Checkbox checked={value} onChange={(e) => handleChange(key, e.target.checked)} />} label={key} />
                  </FormGroup>
                  ) : (
                    <TextField
                      key={key}
                      label={key}
                      value={value || ""}
                      fullWidth
                      size="small"
                      variant="outlined"
                      margin="normal"
                      disabled={key === "id"}
                      onChange={(e) => handleChange(key, e.target.value)}
                    />
                  )
                )}
            </Box>
            <Stack direction="row" spacing={2}>
              <Button color="success" variant="contained" onClick={handleSave}>
                Save
              </Button>
              <Button color="error" variant="contained" onClick={handleDelete}>
                Delete
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
