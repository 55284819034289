import { Grid, Stack, Typography, Button } from '@mui/material';
import React from 'react'
import Oppselect from './Oppselect';

const Opportunity = ({fakeOpportunity ,handleClick}) => {
  return (
    <Grid
    container
    alignItems={"center"}
    justifyContent={"center"}
    paddingBottom={10}
    sx={{
      overflowY: "auto",
      height: "calc(100vh - 100px)",
      padding: "0 10px",
    }}
  >
    <Grid
      item
      xs={12}
      sm={11}
      md={10}
      xl={6}
      backgroundColor={"#FFF"}
      padding={4}
      borderRadius={4}
      boxShadow={"5px 5px 5px 0px rgba(1,1,1,.2)"}
    >
      <Stack spacing={2} direction="column">
        <Typography variant="h6" color={"customColors.default"}>
          Select Opportunity
        </Typography>
        <Grid container justifyContent={"space-between"} spacing={1}>
          <Grid item xs={12} sm={4} md={3} className="!pl-0">
            <Button
              onClick={() => {
                handleClick(fakeOpportunity);
              }}
              sx={{ width: "100%" }}
              variant="contained"
            >
              No Opportunity
            </Button>
          </Grid>
          <Grid item xs={12} sm={8} md={9} className="!pl-0 md:!pl-2">
            {" "}
            <Oppselect />
          </Grid>
        </Grid>
        <Typography color={"customColors.default"}>
          Thank you for selecting an opportunity. It's very important that
          we capture the usage of this tool so that we can improve its
          effectiveness
        </Typography>
        <Typography color={"customColors.default"}>
          Choosing an opportunity will allow you to create a curated
          selection for your discussion! You can also write notes that
          will be automatically saved.
        </Typography>
      </Stack>
    </Grid>
  </Grid>
  )
}

export default Opportunity