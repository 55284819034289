import { Grid, CardActionArea } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";


const defaultAsset = {
  Title: "",
  Heading: "",
  Features: [],
  Children: [],
  Image: "",
  desc: "",
  Type: "factory",
  img: "",
  private: "",
  status: "REVIEW",
};
const defaultMegaFactory = {
  Title: "",
  Heading: "",
  Features: [],
  suites: [
    {
      sortOrder: 1,
      factoryId: "",
      color: "",
      textHeader: "",
      text: "",
    },
  ],
  Image: "",
  desc: "",
  Type: "megafactory",
  exampleflow: "",
  private: "",
  status: "REVIEW",
  library: {
    buttonType: "noButton",
    customButtonText: "",
    customButtonUrl: "",
  },
  exampleColor: "",
  exampleHeader: "",
  exampleText: "",
};

export default function FactoryOne({ asset, setAsset }) {
  const theme = useTheme();
  function handleSelect(type) {
    if (type === "factory") {
      setAsset({ ...defaultAsset, Type: type });
    } else {
      setAsset({ ...defaultMegaFactory, Type: type });
    }
  }
  return (
    <Grid
      container
      sx={{ flexDirection: "row", gap: 4, justifyContent: "space-evenly" }}
    >
      <Card
        sx={{
          maxWidth: 345,
          bgcolor:
            asset && asset.Type === "factory"
              ? "#4D7E70"
              : theme.palette.primary.main,
          padding: 2,
        }}
      >
        <CardActionArea
          onClick={() => {
            handleSelect("factory");
          }}
        >
          <CardMedia
            component="img"
            alt="green iguana"
            style={{ objectFit: "contain" }}
            image="https://tulip.widen.net/content/ironnh8uog/webp/standardFactory%20%281%29.webp"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" color="#FFF">
              Standard Factory
            </Typography>
            <Typography variant="body2" color="#FFFFFF">
              Standard Factories have one image with a set of assets floating on
              top
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>

      <Card
        sx={{
          maxWidth: 345,
          bgcolor:
            asset && asset.Type === "megafactory"
              ? "#4D7E70"
              : theme.palette.primary.main,
          padding: 2,
        }}
      >
        <CardActionArea
          onClick={() => {
            handleSelect("megafactory");
          }}
        >
          <CardMedia
            component="img"
            alt="green iguana"
            style={{ objectFit: "contain" }}
            image="https://tulip.widen.net/content/z891rwyjas/webp/megafactory.webp"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" color="#FFF">
              Mega Factory
            </Typography>
            <Typography variant="body2" color="#FFFFFF">
              Megafactories can have one or multiple images for each suite and a
              selector on the top that lets you switch between views
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
