import { Button, Stack, Typography, TextField } from "@mui/material";
import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { auth } from "../../firebase/config";

export default function ResetPassword({ setResetMode }) {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const handleBack = () => setResetMode(false);
  const handleReset = async () => {
    setMessage("");
    setError("");
    try {
      if (email) {
        await sendPasswordResetEmail(auth, email);
        setEmail("");
        setMessage("Reset password mail has been sent to your email.");
      }
    } catch (error) {
      console.log("Reset Password Error : ", error);
      setError(error);
    }
  };
  return (
    <Stack gap={2}>
      <Typography variant="h6">Reset Password</Typography>
      <TextField
        id="email"
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoComplete="current-email"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Stack
        direction={"row"}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button variant="outlined" onClick={handleBack}>
          Back
        </Button>
        <Button variant="contained" onClick={handleReset}>
          Reset
        </Button>
      </Stack>
      {message && <Typography color="success">{message}</Typography>}
      {error && <Typography color="error">{error}</Typography>}
    </Stack>
  );
}
