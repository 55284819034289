import { useState } from "react";
import { useAuth } from "./useAuth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase/config";
import { useDocs } from "./useDocs";
import { useApp } from "./useApp";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatchUser } = useAuth();
  const { getUserDeets, getUserPrivateId } = useDocs("partners");
  const { setPartnerTheme } = useApp();
  const loginEmail = async (email, password) => {
    setError(null);
    setIsPending(true);
    //sign the user out
    try {
      //sign in user
      //const pers = await setPersistence(auth, browserSessionPersistence)
      const res = await signInWithEmailAndPassword(auth, email, password);
      console.log(res);

      if (!res) {
        throw new Error("could not complete signup");
      }
      //signed in
      setIsPending(false);
      setError(null);
      console.log(res.user);
      const deets = await getUserDeets(res.user.uid);
      console.log(deets);
      dispatchUser({
        type: "LOGIN",
        payload: {
          ...res.user,
          approved: deets.approved,
          partnerAccount: deets.partnerAccount,
          privateId: deets.privateId ?? null,
        },
      });
      if (deets?.privateId) {
        let privateAccount = await getUserPrivateId(deets.privateId);
        if (privateAccount?.theme) {
          setPartnerTheme(privateAccount?.theme);
          const theme = {
            primaryColor: privateAccount?.theme?.primaryColor,
            bgColor: privateAccount?.theme?.bgColor,
            highlight: privateAccount?.theme?.highlight,
          };

          localStorage.setItem("theme", JSON.stringify(theme));
        }
      }
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsPending(false);
    }
  };
  return { loginEmail, loginError: error, loginPending: isPending };
};
