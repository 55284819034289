import { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; 
import { app } from '../../firebase/config';

export const useImageUpload = (file) => {
  const [uploading, setUploading] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!file) return; 

    const uploadImage = async () => {
      setUploading(true);
      setError(null);

      const storage = getStorage(app); 

      try {
        const response = await fetch(file.fileURL);
        const blob = await response.blob(); 

        const storageRef = ref(storage, `images/${Date.now()}-${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, blob);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Track upload progress (optional)
          },
          (error) => {
            setError(error);
            console.error('Error uploading image:', error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setImageURL(downloadURL);
            });
          }
        );
      } catch (error) {
        setError(error);
        console.error('Error fetching or uploading image:', error);
      } finally {
        setUploading(false);
      }
    };

    uploadImage();
  }, [file]);

  return { uploading, imageURL, error };
};

