import React, { useEffect } from "react";
import "./Vtec.css";
import Grid from "@mui/material/Grid";
import Navbar from "../../components/Navbar";
import AssetCard from "../../components/Assetcard";
import Searchpanel from "../../components/Searchpanel";
import Freesolo from "../../components/Freesolo";
import { useApp } from "../../hooks/useApp";
import Assetmodal from "../../components/Assetmodal";
import Oppdrawer from "../../components/Oppdrawer";
import Ipad from "../composable/Ipad";
import { useAuth } from "../../hooks/useAuth";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useDocs } from "../../hooks/useDocs";
import utility from "../../Utils/utility";
import { useCollection } from "../../hooks/useCollection";
import Filter from "../../components/Filter";
import ProgressLoader from "../../components/Common/ProgressLoader";
import Opportunity from "../../components/Opportunity";

const filterKeyToType = {
  apps: "app",
  megafactories: "megafactory",
  factories: "factory",
  videos: "video",
  beacons: "beacon",
};

export default function Vtec({ isPreview = false }) {
  const {
    searchState,
    vtecAssets,
    selectedAsset,
    selectedOpp,
    fakeOpportunity,
    setSelectedOpp,
  } = useApp();
  const { documents: partners } = useCollection("partners");
  const { updateMultipleDocuments } = useDocs("partners");
  const { documents: opportunities } = useCollection("opportunities");
  const { documents: views } = useCollection("views");
  const theme = useTheme();
  const { user, dispatchUser } = useAuth();
  const [userVotes, setUserVotes] = useState([]);
  const [assets, setAssets] = useState();
  const [voteDetails, setVoteDetails] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (views) {
      if(user?.defaultView){
        const defaultView = views.find((x) => x.id === user.defaultView);
        setSelectedFilter(defaultView);
      }
      else{
        const defaultView = views.find((x) => x.isAdminDefault === true);
        setSelectedFilter(defaultView);
      }
     
    }
  }, [views]);

  useEffect(() => {
    if (user && user?.votes) {
      setUserVotes(user?.votes);
    }
    if (isPreview) {
      setSelectedOpp(fakeOpportunity);
    }
  }, [isPreview]);

  let assetList = searchState?.filteredAssets || vtecAssets;

  const isWithinLast7Days = (date) => {
    const now = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(now.getDate() - 7);

    return date >= sevenDaysAgo && date <= now;
  };
  const filterAssets = () => {
    if (selectedFilter) {
      const filterData = selectedFilter?.filter;
      const filteredList =
        assetList &&
        assetList.filter((item) => {
          const filterKey = Object.keys(filterKeyToType).find(
            (key) => filterKeyToType[key] === item.Type
          );
          const filterValues = filterData[filterKey];
          if (filterValues && filterValues.includes("all")) {
            return true;
          }
          return filterValues && filterValues.includes(item.id);
        });
      return filteredList;
    }
  };
  useEffect(() => {
    if (Array.isArray(assetList) && assetList.length > 0 && views) {
      const filteredAssets = (selectedFilter && filterAssets()) || assetList;
      const initialAssets = filteredAssets.filter(
        (x) => !x.hasOwnProperty("isHidden") || x.isHidden !== true
      );
      const newAssets =
        initialAssets &&
        initialAssets.map((item) => {
          const userVoteObj = userVotes.find((vote) =>
            vote.hasOwnProperty(item.id)
          );
          let partnerName = "";
          const userVote = userVoteObj ? userVoteObj[item.id] : null;
          if (item.hasOwnProperty("userid")) {
            const partner =
              partners && partners.find((x) => x.id === item.userid);
            if (partner?.privateId) {
              const opps =
                opportunities &&
                opportunities.find((opp) => opp.id === partner?.privateId);
              if (opps.hasOwnProperty("name")) {
                partnerName = opps?.name ? opps.name : "Partner";
              }
            }
          }
          let isNew = false;
          if (item?.createdAt) {
            isNew = isWithinLast7Days(item.createdAt.toDate());
          }

          return {
            ...item,
            userVote,
            partnerName,
            isNew,
          };
        });
      setAssets(newAssets);
      if (Array.isArray(userVotes) && userVotes.length > 0) {
      } else {
        setAssets(initialAssets);
      }
      setLoader(false);
    }
  }, [opportunities, views, selectedFilter]);

  const handleVote = (asset, voteType) => {
    let updateUserVotes;
    let removeVote = false;
    let toggleVote = false;
    setUserVotes((prevVotes) => {
      const existingVoteIndex = prevVotes.findIndex((vote) =>
        vote.hasOwnProperty(asset.id)
      );
      const voteValue = voteType === "up";
      if (existingVoteIndex !== -1) {
        const vote = prevVotes[existingVoteIndex];
        if (
          (vote[asset.id] && voteType === "up") ||
          (!vote[asset.id] && voteType === "down")
        ) {
          updateUserVotes = prevVotes.filter(
            (_, index) => index !== existingVoteIndex
          );
          removeVote = true;
        } else if (
          (!vote[asset.id] && voteType === "up") ||
          (vote[asset.id] && voteType === "down")
        ) {
          updateUserVotes = [...prevVotes];
          updateUserVotes[existingVoteIndex] = { [asset.id]: voteValue };
          toggleVote = true;
        } else {
          updateUserVotes = [...prevVotes];
          updateUserVotes[existingVoteIndex] = { [asset.id]: voteValue };
        }
      } else {
        updateUserVotes = [...prevVotes, { [asset.id]: voteValue }];
      }
      return updateUserVotes;
    });
    const { upVote, downVote } = asset;
    let newUpVote = upVote || 0;
    let newDownVote = downVote || 0;
    if (removeVote) {
      if (voteType === "up") newUpVote -= 1;
      else newDownVote -= 1;
    } else if (toggleVote) {
      if (voteType === "up") {
        newUpVote += 1;
        newDownVote -= 1;
      } else {
        newUpVote -= 1;
        newDownVote += 1;
      }
    } else {
      if (voteType === "up") newUpVote += 1;
      else newDownVote += 1;
    }
    setAssets((prevAssets) => {
      return prevAssets.map((item) => {
        if (item.id === asset.id) {
          return {
            ...item,
            upVote: newUpVote,
            downVote: newDownVote,
            userVote: removeVote ? null : voteType === "up" ? true : false,
          };
        }
        return item;
      });
    });
    const updatedAsset = {
      ...asset,
      upVote: newUpVote,
      downVote: newDownVote,
    };
    setVoteDetails({
      asset: updatedAsset,
      removeVote,
      voteType,
      updateUserVotes,
    });
  };
  const updateVoteDetails = utility.debounce(async () => {
    if (voteDetails) {
      const { asset, updateUserVotes } = voteDetails;
      try {
        const updates = [
          {
            collection: "partners",
            id: user.uid,
            data: { votes: updateUserVotes },
          },
          {
            collection: "vtec_assets",
            id: asset.id,
            data: {
              upVote: asset.upVote,
              downVote: asset.downVote,
            },
          },
        ];
        await updateMultipleDocuments(updates);
        dispatchUser({
          type: "VOTE_CHANGE",
          payload: {
            ...user,
            votes: userVotes,
          },
        });
      } catch (error) {
        console.error("Error updating documents: ", error);
      }
      // Clear voteDetails to avoid re-running this effect
      setVoteDetails(null);
    }
  }, 500);
  useEffect(() => {
    updateVoteDetails();
  }, [voteDetails]);
  return (
    <Grid container justifyContent={"center"} className="vtec-container">
      <Navbar sx={{ backgroundColor: "grey" }} />
      {selectedOpp && (
        <article
          className={`px-6 xl:px-24 w-full sticky top-[65px] z-10 bg-[${theme.palette.background.default}]`}
        >
          <Grid
            container
            className="lg:flex md:!flex-nowrap"
            gap={2}
            paddingY={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <article className="md:flex items-center gap-2 md-gap-3 w-full ">
              <Grid item xs className="!mb-3 md:!mb-0">
                <Searchpanel />
              </Grid>
              <Grid item xs>
                <Freesolo />
              </Grid>
              <Grid item xs>
                {Array.isArray(views) && views.length > 0 && (
                  <Filter
                    filters={views.filter((item) => item.isHidden !== true && (item.createdByAdmin === true || item.userId === user.uid))}
                    selectedFilter={selectedFilter}
                    onChange={(value) => {
                      setLoader(true);
                      setSelectedFilter(value);
                    }}
                  />
                )}
                {/* <Togglebuttons /> */}
              </Grid>
            </article>
          </Grid>
        </article>
      )}
      {selectedOpp && (
        <Grid
          className="assets-cards"
          container
          paddingBottom={10}
          sx={{ padding: "0px 10px" }}
        >
          <Oppdrawer />
          {loader ? (
            <ProgressLoader />
          ) : (
            <Grid container gap={6} justifyContent={"center"} marginTop={2}>
              {assets &&
                assets.map((asset) => {
                  return (
                    <AssetCard
                      item
                      key={asset.id}
                      asset={asset}
                      onVoteClick={handleVote}
                    ></AssetCard>
                  );
                })}
            </Grid>
          )}
        </Grid>
      )}
      {!user.privateId && !selectedOpp && (
        <Opportunity
          fakeOpportunity={fakeOpportunity}
          handleClick={(opp) => setSelectedOpp(opp)}
        />
      )}
      {selectedAsset && <Assetmodal />}
      <Ipad />
    </Grid>
  );
}
