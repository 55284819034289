import { createContext, useReducer } from "react";
import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase/config";
import { useDocs } from "../hooks/useDocs";

export const AuthContext = createContext();

console.log("mounting auth");
const authReducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      return { ...state, user: null };
    case "AUTH_IS_READY":
      return { ...state, user: action.payload, authIsReady: true };
      case "VOTE_CHANGE":
        return {  ...state, user: action.payload  };
    default:
      return state;
  }
};

export function AuthProvider({ children }) {
  const { getUserDeets, setUserDeets, setEmailVerified } = useDocs("partners");
  const [stateUser, dispatchUser] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
  });

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      let isApproved = false;
      let partnerAccount = null;
      let privateId = null;
      let votes = [];
      let views = [];
      let defaultView = null;
      if (user) {
        getUserDeets(user.uid).then((deets) => {
          if (deets === undefined) {
            deets = {
              approved: false,
              partnerAccount: null,
              privateId: null,
              emailVerified: user.emailVerified,
            };
            setUserDeets(user.uid, user.email, user.emailVerified);
          }
          if (deets?.emailVerified !== user.emailVerified) {
            setEmailVerified(user.uid, user.emailVerified);
          }
          if (
            user &&
            user.providerData.length > 0 &&
            user.providerData[0].providerId === "google.com" &&
            user.email.includes("@tulip.co")
          ) {
            isApproved = true;
            partnerAccount = null;
            privateId = null;
            votes = [];
          } else {
            isApproved = deets.approved ?? false;
            partnerAccount = deets.partnerAccount ?? null;
            privateId = deets.privateId ?? null;
            votes = deets?.votes ?? [];
            views = deets?.views ?? [];
            defaultView = deets?.defaultView ?? null;
          }
          dispatchUser({
            type: "AUTH_IS_READY",
            payload: {
              ...user,
              approved: isApproved ?? false,
              partnerAccount: partnerAccount,
              privateId: privateId,
              votes: votes,
              views: views,
              defaultView: defaultView
            },
          });
        });
      } else {
        console.log("no user ");
        dispatchUser({ type: "AUTH_IS_READY", payload: null });
      }
      unsub();
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...stateUser,
        dispatchUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
