import React from "react";
import { useDraggable } from "@dnd-kit/core";
import "./factorybuilder.css";
import { useTheme } from "@mui/material";

export default function DraggableBeacon({ data, styles }) {
  const theme = useTheme();
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: data.id,
    data: data,
  });
  const style = transform
    ? {
        transform: `translate3d(calc(-50% + ${transform.x}px), calc(-150% + ${transform.y}px), 0)`,
        "--beaconcolor": theme.palette.customColors.highlight,
      }
    : {};

  const customStyle = {
    "--beaconcolor": style["--beaconcolor"]
      ? style["--beaconcolor"]
      : data.catcolor,
  };

  return (
    <div
      ref={setNodeRef}
      className="bubble"
      style={{ ...style, ...customStyle, ...styles }}
      {...listeners}
      {...attributes}
    >
      {data.Title}
    </div>
  );
}
