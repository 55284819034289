import { useState, useEffect } from "react";
import { useApp } from "./useApp";

export const useFetch = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { svgLogo } = useApp();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(svgLogo);
        const text = await response.text();
        setData(text);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }
    if (svgLogo) {
      fetchData();
    }

    // Clean-up function
    return () => {
      // Optionally, you can add clean-up code here
    };
  }, [svgLogo]); //

  return { data, loading, error };
};
