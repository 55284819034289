export const PoweredByLogo = ({style}) => {
  return (
    <div
      style={style}
    >
      <img
        style={{ height: "41.45px", width: "257.73px" }}
        alt="powered by tulip.webp"
        src="https://tulip.widen.net/content/haeip3wtek/webp/powered%20by%20tulip.webp"
      ></img>
    </div>
  );
};
