import {
  Card,
  CardMedia,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import { useApp } from "../hooks/useApp";
import { ListItem, List } from "@mui/material";
import { ListItemText } from "@mui/material";
import { Divider } from "@mui/material";
import React, { useState, useEffect } from "react";
import Tagger from "./Tagger";
import Gobutton from "./Gobutton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import FactoryIcon from "@mui/icons-material/Factory";
import "./Factorymap.css";
import Factorymodal from "./Factorymodal";
import InfoIcon from "@mui/icons-material/Info";
import Videoplayer from "./Videoplayer";
import { useSegment } from "../hooks/useSegment";
import Megafactory from "../pages/composable/Megafactory";
import { useTheme } from "@mui/material/styles";
import LinkIcon from "@mui/icons-material/Link";
import { useAuth } from "../hooks/useAuth";
import { CustomButton } from "./Common/CustomButton";

export default function Assetmodal() {
  const {
    selectedAsset = null,
    setSelectedAsset,
    activeFactory,
    activeVideo,
    activeMegaFactory,
  } = useApp();
  const [factoryLink, setFactoryLink] = useState("");
  const { user } = useAuth();
  const { trackEvent } = useSegment();
  const theme = useTheme();
  useEffect(() => {
    if (selectedAsset) {
      trackEvent("asset opened");
      if (user) {
        setFactoryLink(`/factory/${user.uid}/${selectedAsset.id}`);
      }
    }
  }, []);
  const style = {
    p: 0,
    width: "100%",
    borderRadius: 2,
    border: "1px solid",
    borderColor: "divider",
    backgroundColor: "#FFF",
  };

  return (
    <Modal
      open={selectedAsset !== null && selectedAsset !== undefined}
      onClose={() => setSelectedAsset(null)}
      disableAutoFocus
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Grid
        container
        xs={11}
        md={10}
        justifyContent="center"
        alignItems={"top"}
        overflow={"hidden"}
        borderRadius={"8px 0px 0px 0px"}
        className="flex-nowrap "
      >
        {activeFactory && <Factorymodal />}
        {activeMegaFactory && <Megafactory />}
        <Grid item>
          <svg
            width={35}
            id="topper-notch"
            viewBox="0 0 42 152"
            overflow={"hidden"}
          >
            <path
              d="M41 0H40H1H0V96.5C0 101.6 2 106.3 5.6 109.9L35 139.4C38.2 142.6 40 146.9 40 151.4H41H42V0H41Z"
              fill={theme.palette.background.default}
            ></path>
            <path
              d="M35.7 138.7L6.3 109.3C2.9 105.9 1 101.3 1 96.6V0H0V96.5C0 101.6 2 106.3 5.6 109.9L35 139.4C38.2 142.6 40 146.9 40 151.4H41C41 146.7 39.1 142.1 35.7 138.7Z"
              fill={theme.palette.background.default}
            ></path>
          </svg>
        </Grid>
        <Grid
          item
          xs
          sx={{ backgroundColor: "background.default" }}
          marginY={"auto"}
          borderRadius={"0px 8px 8px 8px"}
          className="p-4 md:p-8"
        >
          <Grid container gap={2}>
            <Grid container className="screen-height overflow-y-auto" gap={2}>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Typography color={"customColors.default"} variant="h5">
                  {selectedAsset.Title}
                </Typography>
                {(selectedAsset.Type === "factory" ||
                  selectedAsset.Type === "megafactory") &&
                  user && (
                    <a
                      href={factoryLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <CustomButton
                        variant="contained"
                        color="secondary"
                        size="small"
                        endIcon={<LinkIcon />}
                      >
                        Link
                      </CustomButton>
                    </a>
                  )}
              </Grid>
              <Grid item xs={12} sm={6} alignItems={"center"}>
                <Card
                  sx={{ boxShadow: "none", backgroundColor: "transparent" }}
                >
                  <CardMedia
                    component="img"
                    image={selectedAsset.img}
                    style={{
                      boxShadow: "none",
                      maxWidth: "100%", // Set width to 100% to fill the entire width of the Card
                      maxHeight: "80vh",
                      objectFit: "cover", // Maintain aspect ratio while covering the entire space
                      objectPosition: "center", // Center the image within the CardMedia
                    }}
                  />
                </Card>
              </Grid>
              {activeVideo && <Videoplayer />}
              <Grid item xs className="!mt-2">
                <Typography variant="h6" color={"customColors.default"}>
                  Key Features
                </Typography>
                {selectedAsset.Features && selectedAsset.Features[0] !== "" && (
                  <List aria-label="mailbox folders" sx={style}>
                    {selectedAsset.Features.map((feature, index) => (
                      <React.Fragment key={index}>
                        <ListItem color={"customColors.default"}>
                          <ListItemText
                            sx={{ color: "customColors.defaultLight" }}
                            primary={feature}
                          />
                        </ListItem>
                        {index < selectedAsset.Features.length - 1 && (
                          <Divider component="li" />
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                )}
              </Grid>
              <Grid item xs={12} md={6} className="!mt-3">
                <Tagger />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"right"}
              alignItems={"bottom"}
              gap={2}
            >
              {selectedAsset.appUrl && (
                <Gobutton
                  text={"Open the App"}
                  startIcon={<ExitToAppIcon />}
                  url={{ type: "beacon", url: selectedAsset.appUrl }}
                />
              )}
              {selectedAsset.editorUrl && (
                <Gobutton
                  text={"Open the Editor"}
                  startIcon={<LaptopMacIcon />}
                  url={{ type: "beacon", url: selectedAsset.editorUrl }}
                />
              )}
              {selectedAsset.contentUrl && (
                <Gobutton
                  text={"More info"}
                  startIcon={<InfoIcon />}
                  url={{ type: "content", url: selectedAsset.contentUrl }}
                />
              )}
              {selectedAsset.video && (
                <Gobutton
                  text={"Play the Video"}
                  startIcon={<InfoIcon />}
                  url={{ type: "video", url: selectedAsset.video }}
                />
              )}
              {selectedAsset.Type === "factory" && (
                <Gobutton
                  text={"Explore the Factory"}
                  startIcon={<FactoryIcon />}
                  url={"factory"}
                />
              )}
              {selectedAsset.Type === "megafactory" && (
                <Gobutton
                  text={"Explore the Factory"}
                  startIcon={<FactoryIcon />}
                  url={"megafactory"}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
