import { useState, useCallback, useMemo } from "react";
import {
  Grid,
  Stack,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Navbar from "../../components/Navbar";
import PreviewTheme from "../../components/theme/PreviewTheme";
import { useCollection } from "../../hooks/useCollection";
import { useDocs } from "../../hooks/useDocs";
import { useApp } from "../../hooks/useApp";
import { useAuth } from "../../hooks/useAuth";
import "./PrivateAccounts.css";
import { emitToast } from "../../common/toastEmitter";

const defaultTheme = {
  primaryColor: "#00263e",
  bgColor: "#E7E6DF",
  highlight: "#F2EEA1",
};

export default function PrivateAccounts() {
  const { documents } = useCollection("partners");
  const [selectedRow, setSelectedRow] = useState(null);
  const { getUserPrivateId, updateDocument } = useDocs("partners");
  const [isDefaultChecked, setIsDefaultChecked] = useState(false);
  const { user } = useAuth();
  const { setPartnerTheme } = useApp();

  const columns = useMemo(
    () => [{ field: "id", headerName: "Private Id", width: 450 }],
    []
  );

  const privateIds = useMemo(() => {
    if (documents) {
      const ids = documents
        .filter((item) => item.privateId)
        .map((item) => item.privateId);
      return [...new Set(ids)].map((id) => ({ id }));
    }
    return [{ id: "loading" }];
  }, [documents]);

  const handleColorChange = useCallback((event, colorType) => {
    setSelectedRow((prev) => ({
      ...prev,
      theme: { ...prev.theme, [colorType]: event.target.value },
    }));
    setIsDefaultChecked(false);
  }, []);

  const handleCheckboxChange = useCallback((event) => {
    const isChecked = event.target.checked;
    setIsDefaultChecked(isChecked);
    if (isChecked) {
      setSelectedRow((prev) => ({
        ...prev,
        theme: defaultTheme,
      }));
    }
  }, []);

  const handleCellClick = useCallback(
    async (params) => {
      try {
        const userData = await getUserPrivateId(params.row.id);
        const selectedrowData = {
          id: userData?.id || params.row.id,
          name: userData?.name || "",
          svg: userData?.svg || "",
          theme: {
            primaryColor:
              userData?.theme?.primaryColor || defaultTheme.primaryColor,
            bgColor: userData?.theme?.bgColor || defaultTheme.bgColor,
            highlight: userData?.theme?.highlight || defaultTheme.highlight,
          },
        };
        setSelectedRow(selectedrowData);
      } catch (err) {
        console.error("Error fetching user data:", err);
      }
    },
    [getUserPrivateId]
  );

  const handleSave = useCallback(() => {
    if (selectedRow) {
      const updatedAccount = {
        ...selectedRow,
        name: selectedRow.name,
        svg: selectedRow.svg,
        theme: selectedRow.theme,
      };
      const { id, ...rest } = updatedAccount;
      updateDocument(id, rest, "opportunities")
        .then(() => {
          emitToast("Private Account updated successfully", "success");
          if (user?.privateId === updatedAccount.id) {
            localStorage.setItem("theme", JSON.stringify(updatedAccount.theme));
            setPartnerTheme(updatedAccount.theme);
          }
        })
        .catch((err) => {
          emitToast("Failed to update account. Please try again.", "error");
        });
    }
  }, [selectedRow, updateDocument, user, setPartnerTheme]);

  const camelToTitleCase = useCallback((camelCaseString) => {
    return camelCaseString
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  }, []);

  return (
    <>
      <Navbar sx={{ backgroundColor: "grey" }} />

      <Grid
        container
        gap={2}
        height="100%"
        padding={2}
        className="accounts_grid lg:!flex-nowrap"
      >
        <Grid
          item
          xs={12}
          md={4}
          sx={{ height: "calc(100% - 65px)", backgroundColor: "white" }}
          padding={1}
        >
          <Typography marginTop={1} fontWeight={600}>
            Private Accounts
          </Typography>
          <DataGrid
            rows={privateIds}
            columns={columns}
            onCellClick={handleCellClick}
            initialState={{
              pagination: { paginationModel: { page: 0, pageSize: 20 } },
            }}
            pageSizeOptions={[20, 40]}
            checkboxSelection={false}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          sx={{ height: "calc(100% - 65px)", backgroundColor: "white" }}
          padding={1}
        >
          <Typography marginTop={1} fontWeight={600}>
            Selected Private Account
          </Typography>
          {selectedRow &&
            Object.entries(selectedRow).map(([key, value]) => (
              <div key={key}>
                {key !== "theme" ? (
                  !Array.isArray(value) && (
                    <TextField
                      label={camelToTitleCase(key)}
                      name={key}
                      value={value || ""}
                      fullWidth
                      size="small"
                      variant="outlined"
                      margin="normal"
                      disabled={key === "id"}
                      onChange={(e) =>
                        setSelectedRow((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.value,
                        }))
                      }
                    />
                  )
                ) : (
                  <Stack
                    direction="row"
                    spacing={2}
                    className="flex-wrap lg:flex-nowrap"
                  >
                    <Grid item xs={12} sm={7}>
                      {Object.entries(value).map(([themeKey, themeValue]) => (
                        <div key={themeKey} className="color-section">
                          <Typography className="color-label">
                            {camelToTitleCase(themeKey)}
                          </Typography>
                          <input
                            type="color"
                            className="color-input"
                            value={themeValue || ""}
                            onChange={(e) => handleColorChange(e, themeKey)}
                          />
                        </div>
                      ))}
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Default"
                        checked={isDefaultChecked}
                        onChange={handleCheckboxChange}
                      />
                    </Grid>
                    <Grid container className="!ml-0 lg:!ml-2">
                      <PreviewTheme partnerTheme={selectedRow.theme} />
                    </Grid>
                  </Stack>
                )}
              </div>
            ))}
          {selectedRow && (
            <Stack
              direction="row"
              spacing={2}
              padding={1}
              justifyContent="flex-end"
            >
              <Button color="success" variant="contained" onClick={handleSave}>
                Save
              </Button>
            </Stack>
          )}
        </Grid>
      </Grid>
    </>
  );
}
