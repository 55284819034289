import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useApp } from "../hooks/useApp";

export default function Bookmarktable() {
  const { vtecAssets, bookmarks } = useApp();
  const filteredAssets =
    bookmarks && bookmarks.length > 0
      ? vtecAssets.filter((asset) => bookmarks.includes(asset.id))
      : null;

  return (
    <TableContainer component={Paper}>
      <Table xs aria-label="simple table">
        <TableBody>
          {filteredAssets &&
            filteredAssets.map((asset) => (
              <TableRow
                key={asset.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {asset.Title}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
