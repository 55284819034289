import { TextField, Autocomplete } from "@mui/material";
import React from "react";

const AutoComplete = ({ name, assets, onChange, defaultValue = [] }) => {
  return (
    <>
      <Autocomplete
        multiple
        id={`autocomplete-${name}`}
        options={assets || []}
        getOptionLabel={(option) => option.title}
        value={(assets && assets.filter((x) => defaultValue.includes(x.id))) || []}
        filterSelectedOptions
        onChange={(event, value) => onChange(event, value)}
        renderInput={(params) => (
          <TextField {...params} label={name} placeholder={name} />
        )}
      />
    </>
  );
};

export default AutoComplete;
