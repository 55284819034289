import { createContext, useReducer, useState } from "react";
import { useCollection } from "../hooks/useCollection";
import { useEffect } from "react";
import { useDocs } from "../hooks/useDocs";
import { useSources } from "../hooks/useSources";
export const AppContext = createContext();

const filterAssets = (searchState) => {
  const {
    searchTerm,
    allAssets,
    useCases,
    filteredAssets,
    toggles,
    bookmarks,
  } = searchState;
  let newFilteredAssets = allAssets;
  console.log("running filter");
  //handle search terms
  if (searchTerm !== "" && searchTerm !== null && searchTerm !== undefined) {
    newFilteredAssets = allAssets.filter(
      (asset) =>
        asset &&
        asset.Title &&
        searchTerm &&
        asset.Title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  } else {
    newFilteredAssets = allAssets;
  }
  if (useCases.length > 0) {
    newFilteredAssets = newFilteredAssets.filter(
      (asset) =>
        asset &&
        asset.tags &&
        asset.tags.some((itemTag) =>
          useCases.some((filterTag) => filterTag.id === itemTag)
        )
    );
  }
  if (toggles.length > 0) {
    newFilteredAssets = newFilteredAssets.filter(
      (asset) => asset && asset.Type && toggles.includes(asset.Type)
    );
  }

  if (
    bookmarks !== null &&
    bookmarks !== undefined &&
    Array.isArray(bookmarks) &&
    bookmarks.length > 0
  ) {
    newFilteredAssets = newFilteredAssets.filter((asset) =>
      bookmarks.includes(asset.id)
    );
  } else {
    newFilteredAssets = newFilteredAssets;
  }
  return {
    ...searchState,
    searchTerm: searchTerm,
    filteredAssets: newFilteredAssets,
  };
};

const searchReducer = (searchState, searchAction) => {
  switch (searchAction.type) {
    case "INITIALIZE":
      return filterAssets({
        ...searchState,
        allAssets: searchAction.payload.assets,
      });
    case "FREE_SOLO":
      const searchTerm = searchAction.payload.searchTerm.toLowerCase();
      return filterAssets({ ...searchState, searchTerm: searchTerm });
    case "USE_CASE":
      return filterAssets({
        ...searchState,
        useCases: searchAction.payload.useCaseSelection,
      });
    case "TOGGLES":
      return filterAssets({
        ...searchState,
        toggles: searchAction.payload.toggleSelection,
      });
    case "BOOKMARKS":
      return filterAssets({
        ...searchState,
        bookmarks: searchAction.payload.bookmarks,
      });
    default:
      return searchState;
  }
};

const appReducer = (state, action) => {
  switch (action.type) {
    case "SELECT_NODE":
      if (
        JSON.stringify(action.payload) === JSON.stringify(state.selectedNode)
      ) {
        return { ...state, selectedNode: null };
      } else {
        return { ...state, selectedNode: action.payload };
      }
    default:
      return state;
  }
};

export function AppProvider({ children }) {
  console.log("appcontext mounted");
  //get all of the assets
  const [bookmarks, setBookmarks] = useState([]);
  const [selectedOpp, setSelectedOpp] = useState(null);
  const [activeMockPc, setActiveMockPc] = useState(null);
  const [oppyState, setOppyState] = useState(false);
  const [activeFactory, setActiveFactory] = useState(null);
  const [activeMegaFactory, setActiveMegaFactory] = useState(null);
  const [svgLogo, setSvgLogo] = useState(null);
  const [playlist, setPlaylist] = useState(null);
  const [activeVideo, setActiveVideo] = useState(null);
  const [register, setRegister] = useState(null);
  const [newAsset, setNewAsset] = useState(null);
  const [activeIpad, setActiveIpad] = useState(null);
  const { ReadDocument } = useDocs("opportunities");
  const [bookmarkFilter, setBookmarkFilter] = useState([]);
  const { mydoc } = ReadDocument(
    "opportunities",
    selectedOpp ? selectedOpp.id : "null"
  );
  const { combinedAssets, vtecAssetsUnsorted } = useSources();
  const [vtecAssets, setVtecAssets] = useState(null);
  const themeData = localStorage.getItem("theme");
  const currentTheme = themeData ? JSON.parse(themeData) : {
    primaryColor: "#00263e",
    bgColor: "#E7E6DF",
    highlight: "#F2EEA1",
  };

  const [partnerTheme, setPartnerTheme] = useState(currentTheme);

  useEffect(() => {
    //handle partner specific stuff
    setBookmarks(mydoc && mydoc.bookmarks ? mydoc.bookmarks : null);
    setSvgLogo(mydoc && mydoc.svg ? mydoc.svg : null);
    setPlaylist(mydoc && mydoc.playlist ? mydoc.playlist : null);
  }, [selectedOpp, mydoc]);

  //establish the search initial values which should be empty
  const [searchState, searchDispatch] = useReducer(searchReducer, {
    allAssets: vtecAssets,
    filteredAssets: vtecAssets,
    useCases: [],
    toggles: [],
    searchTerm: "",
    bookmarks: [],
  });

  const [selectedAsset, setSelectedAsset] = useState(null);
  const [state, dispatch] = useReducer(appReducer, {
    selectedNode: null,
  });
  const { documents: useCases } = useCollection("use_cases");

  useEffect(() => {
    if (bookmarkFilter.length !== 0) {
      beginBookmarks(bookmarks);
    }
  }, [bookmarkFilter]);

  useEffect(() => {
    // This effect will run once during component mount
    console.log("useeffect triggered");
    if (!searchState.allAssets !== vtecAssets) {
      searchDispatch({ type: "INITIALIZE", payload: { assets: vtecAssets } });
    }
    //refresh the asset plz
    if (selectedAsset) {
      const updatedAsset = vtecAssets.find(
        (asset) => asset.id === selectedAsset.id
      );
      if (updatedAsset) {
        setSelectedAsset(updatedAsset);
        console.log("asset updated");
      }
    }
  }, [vtecAssets, selectedAsset]); // Only run the effect when vtecAssets changes

  const beginSearch = (searchTerm) => {
    searchDispatch({ type: "FREE_SOLO", payload: { searchTerm: searchTerm } });
  };

  const beginToggle = (toggleSelection) => {
    searchDispatch({
      type: "TOGGLES",
      payload: { toggleSelection: toggleSelection },
    });
  };

  const beginUseCase = (useCaseSelection) => {
    searchDispatch({
      type: "USE_CASE",
      payload: { useCaseSelection: useCaseSelection },
    });
  };

  const beginBookmarks = () => {
    if (bookmarkFilter.length > 0) {
      searchDispatch({ type: "BOOKMARKS", payload: { bookmarks: bookmarks } });
    } else {
      searchDispatch({ type: "BOOKMARKS", payload: { bookmarks: null } });
    }
  };

  const selectNode = (selectedNode) => {
    dispatch({ type: "SELECT_NODE", payload: selectedNode });
  };

  const fakeOpportunity = {
    id: "001xxxxxxxxxxxxxxx",
    name: "Sample Opportunity",
    ageindays: 30,
    discovery_call_date_status__c: "Completed",
    region__c: "North America",
    generated_by__c: "John Doe",
    opportunity_owner_name__c: "Jane Smith",
    type: "New Business",
    sdr_notes__c: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    primary_use_case__c: "Marketing Automation",
    opportunity_record_type_name__c: "Standard",
    nextstep: "Follow-up call scheduled",
    stagename: "Prospecting",
    deal_type__c: "Software",
    created_by_name__c: "Admin",
    account_owner_raw_name__c: "Sarah Johnson",
    account_industry_category__c: "Technology",
    createddate: "2024-03-06T12:00:00Z",
    da_influence__c: "High",
    decision_criteria__c: "Cost-effectiveness, Features",
    decision_process__c: "Multiple stakeholders involved",
    discovery_call_date__c: "2024-03-01",
    identify_pain__c: "Lack of automation in current processes",
    industry__c: "Software",
    lastactivitydate: "2024-03-05",
  };
  return (
    <AppContext.Provider
      value={{
        vtecAssets,
        setVtecAssets,
        combinedAssets,
        useCases,
        selectedOpp,
        oppyState,
        fakeOpportunity,
        setOppyState,
        setSelectedOpp,
        newAsset,
        setNewAsset,
        beginSearch,
        beginBookmarks,
        activeFactory,
        setActiveFactory,
        activeVideo,
        setActiveVideo,
        bookmarkFilter,
        setBookmarkFilter,
        beginUseCase,
        beginToggle,
        searchState,
        vtecAssetsUnsorted,
        ...state,
        svgLogo,
        bookmarks,
        dispatch,
        playlist,
        register,
        setRegister,
        selectedAsset,
        setSelectedAsset,
        activeMockPc,
        setActiveMockPc,
        activeIpad,
        setActiveIpad,
        activeMegaFactory,
        setActiveMegaFactory,
        selectNode,
        partnerTheme,
        setPartnerTheme
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
