export default function Lines({ nodes }) {
  const lines = () => {
    let flowNodes = nodes.Children.filter((node) => node.flow);
    let drawLines = [];
    let prevNode = null;
    flowNodes.forEach((node) => {
      if (prevNode) {
        drawLines.push({
          id: `${prevNode.id}-${node.id}`,
          x1: prevNode.Left,
          y1: prevNode.Top,
          x2: node.Left,
          y2: node.Top,
          stroke: prevNode.color,
          strokeWidth: "10px",
        });
      }
      prevNode = node;
    });
    return drawLines;
  };
  return (
    <svg width="100%" height="100%" style={{ position: "absolute" }}>
      {lines().map((line) => (
        <g key={line.id}>
          <line
            x1={line.x1}
            y1={line.y1}
            x2={line.x2}
            y2={line.y2}
            stroke={"#B6DCE1"}
            strokeWidth={line.strokeWidth}
          />

          <line
            className="movingline"
            x1={line.x1}
            y1={line.y1}
            x2={line.x2}
            y2={line.y2}
            stroke={"black"}
            strokeWidth={3}
          />
        </g>
      ))}
    </svg>
  );
}
