import { Box, CircularProgress } from "@mui/material";
import React from "react";

const ProgressLoader = () => {
  return (
    <Box style={{ width: "100%", height: "70vh" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <CircularProgress size={80} thickness={4} />
      </Box>
    </Box>
  );
};

export default ProgressLoader;
