import { useState, useEffect } from "react";

export const useCdm = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  function extractStringFromParentheses(str) {
    // Regular expression to match content inside parentheses
    const regex = /\((.*?)\)\s*(.*)/;

    // Match content inside parentheses
    const match = str.match(regex);

    // Extracted string inside parentheses
    const insideParentheses = match ? match[1].trim() : "";

    // Part of the string after parentheses
    const tableLabel = match ? match[2].trim() : str;
    return [insideParentheses, tableLabel];
  }

  const cdms = [
    {
      Title: "Discrete Model v2",
      Url: "https://common-table-model.s3.amazonaws.com/v2.json",
    },
  ];
  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedData = [];
        for (const cdm of cdms) {
          const response = await fetch(cdm.Url);
          if (!response.ok) {
            throw new Error(`Network response for ${cdm.Title} was not ok`);
          }
          const jsonData = await response.json();
          const jsonUpdate = jsonData.map((table) => {
            let [insideParentheses, tableLabel] = extractStringFromParentheses(
              table.label
            );
            return {
              ...table,
              stereotype: insideParentheses,
              title: tableLabel,
            };
          });
          fetchedData.push({ Title: cdm.Title, Data: jsonUpdate });
        }
        setData(fetchedData);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }

    fetchData();

    // Clean-up function
    return () => {
      // Optionally, you can add clean-up code here
    };
  }, []); // Dependency array ensures this effect runs only when cdms changes

  return { data, loading, error };
};
