import * as React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {useTheme } from "@mui/material/styles"

// const hexToRgb = (hex, alpha) => {
//   // Remove the leading # if present
//   hex = hex.replace(/^#/, "");

//   // Parse the hex value into its components
//   let r = parseInt(hex.substring(0, 2), 16);
//   let g = parseInt(hex.substring(2, 4), 16);
//   let b = parseInt(hex.substring(4, 6), 16);

//   // Return the RGB color as an object
//   return `rgba(${r}, ${g}, ${b} , ${alpha})`;
// };

const AntTabs = styled(Tabs)(({ theme }) => ({
  minHeight: 1,
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.customColors.highlight, // Using the primary color from the theme
    width: "100%",
  },
}));

const AntTab = styled((props) => (
  <Tab disabled={props.value ? false : true} disableRipple {...props} />
))(({ theme, color }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: 16,
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
  minHeight: 0,
  alignItems: "flex-start",
  paddingLeft: 0,
  paddingBottom: 8,
  color: color,
  borderBottom: `2px solid #BECDD5`,
  fontFamily: ["Messina Sans"].join(","),
  "&:hover": {
    color: "rgba(253, 252, 248, .8)",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: theme.palette.customColors.highlight,
    fontWeight: theme.typography.fontWeightMedium,
  },
  "&.Mui-disabled": {
    color: color ? color : "rgba(253, 252, 248, .3)",
    fontWeight: theme.typography.fontWeightMedium,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "",
  },
  "& .MuiTab-wrapped": {
    width: "100%",
    justifyContent: "flex-start", // Align content to the start to fill the available space
    alignItems: "self-start",
  },
}));

export default function CmesTabs({
  handleSuiteChange,
  selectedSuite,
  suites,
  tab,
}) {
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    handleSuiteChange(newValue);
  };

  return (
    <Box paddingX={2}>
      <Box sx={{ bgcolor: "transparent", width: "100%" }}>
        <AntTabs
          variant="fullWidth"
          value={selectedSuite.Title}
          onChange={handleChange}
          aria-label="ant example"
          color={theme.palette.customColors.highlight}
        >
          {suites.map((suite) => (
            <AntTab
              label={suite.Title}
              key={suite.Title}
              value={tab == "Example" ? null : suite.Title}
              color={theme.palette.customColors.gray}
            />
          ))}
        </AntTabs>
        <Box sx={{ p: 1 }} />
      </Box>
    </Box>
  );
}
