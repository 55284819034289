import { Box, IconButton, Modal } from "@mui/material";
import { useApp } from "../../hooks/useApp";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function MockPc() {
  const { activeMockPc, setActiveMockPc } = useApp();
  const handleClose = () => {
    setActiveMockPc(null);
  };
  return (
    <React.Fragment>
      <Modal
        open={activeMockPc}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%", // Adjust the width as needed
            height: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          <IconButton
            onClick={handleClose}
            size="large"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              zIndex: 1000,
              backgroundColor: "#000",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#000", 
                color: "#fff",
              },
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: 0,
              paddingBottom: "56.25%",
            }}
          >
            <iframe
              src={activeMockPc}
              webkitallowfullscreen
              title="lib"
              mozallowfullscreen
              allowfullscreen
              allow-same-origin
              muted
              frameborder="0"
              allowtransparency="true"
              style={{
                position: "absolute",
                overflow: "scroll",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
