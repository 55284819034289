import { useState } from "react";
import { useApp } from "./useApp";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/config";

export const useSegment = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { selectedAsset, selectedOpp } = useApp();

  const trackEvent = async (eventName) => {
    setError(null);
    setIsPending(true);

    try {
      //signup user
      const res = await httpsCallable(
        functions,
        "track_event"
      )({
        event: eventName,
        deets: { ...selectedAsset, opportunityId: selectedOpp.id },
      });
      if (!res) {
        throw new Error("could not complete signup");
      }

      console.log(res);
      setIsPending(false);
      setError(null);
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsPending(false);
    }
  };

  const trackDirectEvent = async (eventName, asset, opportunityId) => {
    setError(null);
    setIsPending(true);
    console.log(asset, opportunityId);
    try {
      //signup user
      const res = await httpsCallable(
        functions,
        "track_event"
      )({
        event: eventName,
        deets: { ...asset, opportunityId: opportunityId },
      });
      if (!res) {
        throw new Error("could not complete signup");
      }

      console.log(res);
      setIsPending(false);
      setError(null);
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsPending(false);
    }
  };

  const trackVOC = async (notes) => {
    setError(null);
    setIsPending(true);

    try {
      //signup user
      const res = await httpsCallable(
        functions,
        "track_voc"
      )({
        event: "voc_ai",
        deets: { opportunityId: selectedOpp.id, notes: notes },
      });
      if (!res) {
        throw new Error("could not complete signup");
      }

      console.log(res);
      setIsPending(false);
      setError(null);
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsPending(false);
    }
  };
  return { error, isPending, trackEvent, trackVOC, trackDirectEvent };
};
