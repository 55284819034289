import { createTheme } from "@mui/material";
import color from "../../Utils/ColorUtil";

const createCustomTheme = (mainColor, bgcolor, highlight) => {

  const isLightOrDark = color.isLightOrDark(mainColor);
  const lightColor = color.getLighterAndDarker(mainColor).lighter;
  const lighterColor = color.getLighterAndDarker(lightColor).lighter;
  const colorWhite = isLightOrDark === "light" ? "#000000" : "#FFFFFF";
  const grayColor = isLightOrDark === "light" ? "#000000" : "#becdd5";
  highlight = highlight ?? "#F2EEA1";
  const isHighlightLightOrDark = color.isLightOrDark(highlight);

  const getColor = (colorMode, color, isDefault) => {
    if(isDefault){
      return mainColor !== "#00263e" ? isLightOrDark == "light" ? "#000000" : color : "#00263e"
    }
    else{
      return mainColor !== "#00263e" ? colorMode : color;
    }
  }
   
  return createTheme({
    palette: {
      background: {
        paper: "#FFFFFF",
        default: bgcolor ? bgcolor : "#E7E6DF",
      },
      primary: {
        main: mainColor,
        contrastText:  colorWhite,
      },
      secondary: {
        main: getColor(lightColor, "#003b71", false),
        contrastText: colorWhite
      },
      info: {
        main: getColor(lighterColor, "#194f91", false),
        contrastText: colorWhite
      },
      customColors: {
        default : getColor(null,mainColor, true),
        defaultLight: getColor(null,lightColor, true),
        white : colorWhite,
        gray : grayColor,
        highlight: highlight ? highlight : "#F2EEA1",
        highlightText: isHighlightLightOrDark === "light" ?  "#000000" : "#FFFFFF"
      }
    },
  });
};
export default createCustomTheme;

