import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid } from "@mui/material";
import { useApp } from "../hooks/useApp";

export default function Freesolo() {
  const { vtecAssets, beginSearch } = useApp();
  const sortedOptions = vtecAssets
    ? vtecAssets
        .slice() // Create a shallow copy to avoid mutating the original array
        .sort((a, b) => a.Title.localeCompare(b.Title))
        .map((option) => option.Title)
    : [{ Title: "Loading..." }];
  return (
    <Grid item>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        sx={{ backgroundColor: "#FFF" }}
        disableClearable
        xs={12}
        onInputChange={(event, newValue) => {
          beginSearch(newValue);
        }}
        //onInputChange={function(event, value, reason) {console.log(value)}}
        options={sortedOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search for examples"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
    </Grid>
  );
}
