import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { useDocs } from '../../hooks/useDocs';
import { useEffect, useState } from 'react';


function copyCutHandler(handler) {
    return (evt) => {
      const data = handler(evt);  // Get the data from the handler (including files)
      if (data != null) {
        const clipboardData = evt.clipboardData;
        if (clipboardData != null) {
          Object.keys(data).forEach((mimeType) => {
            const value = data[mimeType];
            if (value != null) {
              if (typeof value === 'string') {
                clipboardData.setData(mimeType, value);  // Set string data for regular MIME types
              } else if (value instanceof File) {

                // Instead of ClipboardItem, add the File directly
                clipboardData.items.add(value);  // Directly add the File object to the clipboard
              } else {
                throw new Error('Unsupported clipboard item type');
              }
            }
          });
        }
        evt.preventDefault();  // Prevent the default behavior (e.g., copying text)
      }
    };
  }
  


export default function SeedCard({ data, instance }) {
  const { deleteSeed } = useDocs('seeds');
  const [handleCopy, setHandleCopy] = useState(null);

  const emptyImageFile = new File([], 'image.png', { type: 'image/png' });

  const triggerCopy = () => {
    // Register the custom copy handler on button click
    const newCopy = {
        ...data.seedData['application/x.tulip.clipboard.step-editor+json'], sourceCustomer:`${instance}.tulip.co`
    }
    const copyHandler = copyCutHandler(() => ({
      "application/x.tulip.clipboard.step-editor+json": JSON.stringify(newCopy),
      "image/svg+xml": '',  // Example SVG
      "image/gif": '',    // Placeholder for empty GIF data
      "image/jpeg": '',   // Placeholder for empty JPEG data
      "Files": emptyImageFile,         // Placeholder for file data if needed
    }));
        
    // Attach event listener when the button is clicked
    document.addEventListener('copy', copyHandler);

    // Programmatically trigger the copy event
    document.execCommand('copy');

    // Cleanup listener after the copy operation is complete
    setTimeout(() => {
      document.removeEventListener('copy', copyHandler);
    }, 0);

};

  return (
    <Card sx={{ width: 345 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={data.imgurl}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {data.title}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {data.desc}
        </Typography>
      </CardContent>
      <CardActions>
        <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
          <Button size="small" color="warning" onClick={() => deleteSeed(data)}>
            Delete
          </Button>
          <Button
            size="small"
            variant="contained"
            color="success"
            disabled = {!instance}
            onClick={triggerCopy}
          >
            Copy
          </Button>
        </Stack>
      </CardActions>
    </Card>
  );
}
