import { useState, useEffect, useCallback } from 'react';

export const usePaste = () => {
  const [pastedData, setPastedData] = useState(null);

  const clearPastedData = useCallback(() => {
    setPastedData(null);
  }, []);
  
  useEffect(() => {
    const handlePaste = (evt) => {
      const clipboardData = evt.clipboardData;
      const data = {};

      // Loop through all types in clipboardData
      Array.from(clipboardData.types).forEach((mimeType) => {
        if (mimeType === 'Files') {
          // Check for file data in clipboard
          const file = clipboardData.items[0]?.getAsFile();

          if (file) {
            // If the file is an image, create an object URL for previewing
            const fileURL = URL.createObjectURL(file);
            data[mimeType] = { file, fileURL }; // Store both the file and the object URL
          }
        } else {
          // Handle text-based data (like JSON or plain text)
          const value = clipboardData.getData(mimeType);
          try {
            data[mimeType] = JSON.parse(value);  // Try to parse JSON
          } catch (e) {
            data[mimeType] = value;  // Fallback to plain text if parsing fails
          }
        }
      });

      // Set the pasted data (including the image or other text data)
      setPastedData(data);
      evt.preventDefault();  // Prevent the default paste behavior
    };

    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  return { pastedData, clearPastedData };
};
