import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import { useCollection } from "../hooks/useCollection";
import { useApp } from "../hooks/useApp";

export default function Searchpanel() {
  const { documents: useCases } = useCollection("use_cases");
  const { beginUseCase } = useApp();

  // Define the custom order for categories
  const customOrder = [
    "Digital Guidance",
    "Production Management",
    "Quality",
    "Compliance & Traceability",
    "Maintenance",
    "Inventory",
  ];

  // Sort the array based on the custom order
  const sortedDocuments = useCases
    ? useCases.sort(
        (a, b) =>
          customOrder.indexOf(a.solution) - customOrder.indexOf(b.solution)
      )
    : [{ usecase: "loading" }];

  return (
    
    <Grid container>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={
            sortedDocuments.length > 0
              ? sortedDocuments
              : [{ usecase: "loading" }]
          }
          sx={{ backgroundColor: "#FFF" }}
          getOptionLabel={(option) => option.usecase}
          groupBy={(option) => option.solution}
          filterSelectedOptions
          onChange={(event, newValue) => {
            beginUseCase(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Use Cases"
              placeholder="Add use cases"
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
