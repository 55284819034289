import { useState, useEffect } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/config";

export const useCollection = (collectionid) => {
  const [documents, setDocuments] = useState(null);
  const [error, setError] = useState(null);
  const [commentPending, setCommentPending] = useState(false);

  //fires once and whenever the collection changes
  useEffect(() => {
    setCommentPending(true);
    //set up a real time listener to a firestore collection
    let ref = collection(db, collectionid);
    const unsubscribe = onSnapshot(
      ref,
      (snapshot) => {
        let results = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });

        setCommentPending(false);
        setDocuments(results);
        setError(null);
      },
      (error) => {
        console.log(error);
        setCommentPending(false);
        setError("could not fetch the data");
      }
    );
    //unsub on unmount
    return () => unsubscribe();
  }, [collectionid]);
  return { documents, error, commentPending };
};
