import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Grid, IconButton, Stack } from "@mui/material";
import { CardActionArea } from "@mui/material";
import { useApp } from "../hooks/useApp";
import Bookmark from "./Bookmark";
import { useTheme } from "@mui/material/styles";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { useAdmin } from "../hooks/useAdmin";

export default function AssetCard({ asset, onVoteClick }) {
  const { setSelectedAsset } = useApp();
  const { myAdminRecord } = useAdmin();
  const theme = useTheme();
  const handleCardClick = (value) => {
    setSelectedAsset(value);
  };

  return (
    <Grid
      container
      maxWidth={380}
      borderRadius={"8px 0px 0px 0px"}
      position={"relative"}
    >
      {asset?.isNew && (
        <span
          className="new-flag"
          style={{
            position: "absolute",
            right: "-5px",
            top: "-9px",
            zIndex: "9",
            borderRadius: "26px",
            padding: "3px 12px",
            fontSize: "12px",
            background: theme.palette.customColors.highlight,
            color: theme.palette.customColors.highlightText,
            border: `2px solid ${theme.palette.customColors.highlightText}`
          }}
        >
          New
        </span>
      )}

      <Grid item>
        <svg
          width={15}
          id="topper-notch"
          viewBox="0 0 42 152"
          overflow={"hidden"}
          style={{borderRadius:"8px 0 0 0"}}
        >
          <path
            d="M41 0H40H1H0V96.5C0 101.6 2 106.3 5.6 109.9L35 139.4C38.2 142.6 40 146.9 40 151.4H41H42V0H41Z"
            fill={theme.palette.primary.main}
          ></path>
          <path
            d="M35.7 138.7L6.3 109.3C2.9 105.9 1 101.3 1 96.6V0H0V96.5C0 101.6 2 106.3 5.6 109.9L35 139.4C38.2 142.6 40 146.9 40 151.4H41C41 146.7 39.1 142.1 35.7 138.7Z"
            fill={theme.palette.primary.main}
          ></path>
        </svg>
      </Grid>
      <Grid xs item maxWidth={345}>
        <Card
          sx={{
            boxShadow: "none",
            overflow: "hidden",
            borderRadius: "0px 8px 8px 8px",
          }}
        >
          <CardActionArea onClick={() => handleCardClick(asset)}>
            <Grid>
              {asset.source && asset.source === "library" && (
                <div
                  style={{
                    position: "absolute",
                    width: "300px",
                    textAlign: "center",
                    padding: "4px",
                    backgroundColor: theme.palette.customColors.highlight,
                    transform: "rotate(-45deg)",
                    bottom: "10px",
                    right: "-121px",
                    color: theme.palette.customColors.highlightText,
                  }}
                >
                  Library
                </div>
              )}
              {myAdminRecord && myAdminRecord.admin && asset?.partnerName && (
                <div
                  style={{
                    position: "absolute",
                    width: "300px",
                    textAlign: "center",
                    padding: "4px",
                    backgroundColor: theme.palette.customColors.highlight,
                    transform: "rotate(-45deg)",
                    bottom: "10px",
                    right: "-121px",
                    color: theme.palette.customColors.highlightText,
                  }}
                >
                  {asset.partnerName}
                </div>
              )}
              <Grid
                item
                container
                xs={12}
                sx={{ backgroundColor: "primary.main" }}
                height={"60px"}
                alignItems={"top"}
                justifyContent={"space-between"}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    color="customColors.white"
                    overflow={"hidden"}
                  >
                    {asset.Title}
                  </Typography>
                </CardContent>
                <CardContent sx={{ position: "absolute", top: -5, right: -10 }}>
                  <Bookmark asset={asset} />
                </CardContent>
              </Grid>
              <Grid></Grid>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: "secondary.main",
                  boxShadow: "0 4px 8px rbga(0,0,0,.8)",
                }}
                className="toBottomGradient"
              >
                <CardMedia
                  sx={{ height: 200, margin: "auto" }}
                  image={asset.Image}
                  title={asset.Title}
                  style={{
                    backgroundSize: "cover", // or 'contain'
                    backgroundPosition: "center", // you can adjust this based on your preference
                  }}
                />
                <CardContent>
                  <Typography
                    variant="body2"
                    color="customColors.white"
                    sx={{
                      height: 60,
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 3, // Adjust the number of lines as needed
                    }}
                  >
                    {asset.Heading}
                  </Typography>
                  <Stack
                    backgroundColor={"info.main"}
                    direction="row"
                    alignItems="center"
                    //border={1}
                    borderRadius={5}
                    className="!inline-flex"
                  >
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        onVoteClick(asset, "up");
                      }}
                    >
                      {asset?.userVote ? (
                        <ThumbUpAltIcon sx={{ color: "#fff" }} />
                      ) : (
                        <ThumbUpOffAltIcon sx={{ color: "#fff" }} />
                      )}
                    </IconButton>
                    <Typography color={"customColors.white"} variant="body2">
                      {asset?.upVote || asset?.downVote
                        ? (asset?.upVote || 0) - (asset?.downVote || 0)
                        : "Vote"}
                    </Typography>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        onVoteClick(asset, "down");
                      }}
                    >
                      {asset?.userVote === false ? (
                        <ThumbDownAltIcon sx={{ color: "#fff" }} />
                      ) : (
                        <ThumbDownOffAltIcon sx={{ color: "#fff" }} />
                      )}
                    </IconButton>
                  </Stack>
                </CardContent>
              </Grid>
            </Grid>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
}
