import React from "react";
import AssetCard from "../../components/Assetcard";
import { Grid, Stack, TextField } from "@mui/material";

export default function StepOne({ asset, setAsset }) {
  function handleChange(event) {
    const newAsset = { ...asset, [event.target.id]: event.target.value };
    setAsset(newAsset);
  }
  return (
    <Grid container sx={{ flexDirection: "row", gap: 4 }}>
      <Grid item xs={12} sm={5} sx={{ justifyContent: "center", alignItems: "center" }}>
        <AssetCard asset={asset} />
      </Grid>
      <Grid item xs>
        <Stack direction={"column"} gap={4}>
          <TextField
            id="Title"
            label="Title"
            fullWidth
            value={asset.Title}
            onChange={(event) => {
              handleChange(event);
            }}
          />
          <TextField
            id="Heading"
            label="Heading"
            fullWidth
            value={asset.Heading}
            onChange={(event) => {
              handleChange(event);
            }}
          />
          <TextField
            id="Image"
            label="Image"
            fullWidth
            value={asset.Image}
            onChange={(event) => {
              handleChange(event);
            }}
          />
          <TextField
            id="private"
            label="Private Asset ID"
            fullWidth
            value={asset.private}
            onChange={(event) => {
              handleChange(event);
            }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
