import { Box, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Navbar from "../../components/Navbar";
import { useState } from "react";
import LinearStepper from "./Stepper";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import Review from "./Review";

const defaultAsset = {
  Title: "",
  Heading: "",
  Features: [],
  Children: [],
  Image: "",
  desc: "",
  Type: "app",
  appUrl: "",
  flow: false,
  editorUrl: "",
  contentUrl: "",
  img: "",
  video: "",
  private: "",
  status: "REVIEW",
};

export default function Create() {
  const [asset, setAsset] = useState(defaultAsset);
  const [activeStep, setActiveStep] = useState(0);
  function resetAsset() {
    setAsset(defaultAsset);
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        gap: 4,
      }}
    >
      <Navbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "center",
          gap: 4 }}
          className="w-full px-3 md:w-[80%]"
      >
        <Typography variant="h5" sx={{ alignSelf: "start" }}>
          Create vTEC Asset
        </Typography>
        <LinearStepper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          asset={asset}
          resetAsset={resetAsset}
        >
          <Paper
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              padding: 4,
              margin: 2,
              gap: 2,
            }}
            elevation={4}
            margin={10}
          >
            {activeStep == 0 && <StepOne asset={asset} setAsset={setAsset} />}
            {activeStep == 1 && <StepTwo asset={asset} setAsset={setAsset} />}
            {activeStep == 2 && <StepThree asset={asset} setAsset={setAsset} />}
            {activeStep == 3 && <Review asset={asset} setAsset={setAsset} />}
          </Paper>
        </LinearStepper>
      </Box>
    </Box>
  );
}
