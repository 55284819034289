import { Autocomplete, TextField } from "@mui/material";
import React from "react";

const Filter = ({filters, selectedFilter, onChange}) => {
  return (
    <Autocomplete
      className="w-100"
      options={filters}
      getOptionLabel={(option) => option.name}
      value={selectedFilter}
      sx={{ backgroundColor: "#FFF" }}
      id="filter"
      disableClearable
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      renderInput={(params) => <TextField {...params} label="Filter" />}
    />
  );
};

export default Filter;
