import React, { useState, useRef, useEffect } from "react";
import { DndContext } from "@dnd-kit/core";
import DraggableBeacon from "./DraggableBeacon";

export default function FactoryDrag({ asset, setAsset }) {
  const mainRef = useRef(null);
  const [selectedFactory, setSelectedFactory] = useState(null);
  const createlayerStyle = {
    width: "300px",
    height: "fit-content",
    backgroundColor: "rgba(248, 244, 231, 1)",
    border: "1px solid rgba(248, 244, 231, 1)",
    margin: "20px 20px 20px 0px",
    borderRadius: "0px 10px 10px 0px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    alignSelf: "start",
    padding: "10px",
    gap: "10px",
  };
  
  function handleDragEnd(event) {
    const beacon = selectedFactory.Children.find(
      (x) => x.id === event.active.id
    );
    const mainWidth = mainRef.current.offsetWidth;
    const mainHeight = mainRef.current.offsetHeight;
    const newLeft =
      parseFloat(beacon.Left.replace("%", "")) +
      (event.delta.x / mainWidth) * 100;
    const newTop =
      parseFloat(beacon.Top.replace("%", "")) +
      (event.delta.y / mainHeight) * 100;
    beacon.Left = `${newLeft}%`;
    beacon.Top = `${newTop}%`;
    const _beacons = selectedFactory.Children.map((x) => {
      if (x.id === beacon.id) return beacon;
      return x;
    });
    setSelectedFactory((prev) => ({ ...prev, Children: _beacons }));
  }

  // function handleSave() {
  //   const newChildren = selectedFactory.Children.map((child) => ({
  //     id: child.id,
  //     Left: child.Left,
  //     Top: child.Top,
  //   }));
  //   const newFactory = { ...selectedFactory, Children: newChildren };
  // }

  // function handleSelectFactory(factory) {
  //   //does the facotry have a chidren key?
  // }

  useEffect(() => {
    if (asset.Type === "factory") {
      //this is a factory so load hte children and image directly
      setSelectedFactory(asset);
    }
  }, [asset]);

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <div
        id="main-container"
        style={{
          display: "flex",
          flexDirection: "row",
          width: "fit-content",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="navStandardGradient"
      >
        <div
          id="factorycontainer"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            id="factory"
            ref={mainRef}
            style={{
              backgroundColor: "transparent",
              position: "relative",
              height: "fit-content",
              maxWidth: "100%",
            }}
          >
            <div
              id="beaconlayer"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
            >
              {selectedFactory &&
                selectedFactory.Children &&
                selectedFactory.Children.map((child) => (
                  <DraggableBeacon
                    id={child.id}
                    key={child.id}
                    data={{ ...child, catcolor: "#B6DCE1" }}
                    styles={{
                      position: "absolute",
                      left: child.Left,
                      top: child.Top,
                    }}
                  >
                    {child.Title}
                  </DraggableBeacon>
                ))}
            </div>

            <img
              src={
                selectedFactory && selectedFactory.img
                  ? selectedFactory.img
                  : "https://tulip.widen.net/content/hy3p5vlugh/webp/no%20image.webp"
              }
              style={{
                maxWidth: "100%",
                maxHeight: "800px",
                opacity: 1,
                backgroundColor: "transparent",
              }}
            ></img>
          </div>
        </div>
      </div>
    </DndContext>
  );
}
