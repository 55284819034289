import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useEffect, useState } from 'react';
import { Container, Grid, Stack, TextField } from '@mui/material';
import { StayPrimaryLandscape } from '@mui/icons-material';
import SeedCard from './SeedCard';
import { usePaste } from './usePaste';
import SeedModal from './SeedModal';
import { useCollection } from '../../hooks/useCollection';
import { useCopy } from './useCopy';

const Seedvault = () => {
  const exampleData = ['test','test2','test3','test4', 'test5','test6','test7','test8','test9','test10'];
  const { documents: seeds } = useCollection("seeds");
  const {pastedData, clearPastedData} = usePaste()
  const [instance, setInstance] = useState(null)


  const handleInput = (event) => {
    const { value, id } = event.target;
    setInstance(value);
};
  return (
    <Box sx={{ flexGrow: 1 }}>
        <SeedModal pastedData={pastedData} clearPastedData={clearPastedData}/>
    <AppBar position="fixed">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Seedvault
        </Typography>
        <Typography>Your-instance.tulip.co </Typography>
            <TextField
            hiddenLabel
            variant="outlined"
            size='small'
            value={instance || null}
            onChange={handleInput}
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: 'white',
                color: 'black',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'black', // optional, to change the outline color
                },
                '&:hover fieldset': {
                  borderColor: 'gray', // optional, for hover effect
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'black', // optional, for focused effect
                },
              },
            }}
          />
      </Toolbar>
    </AppBar>
    <Box sx={{px:2, py:10, justifyContent:'center'}}>
        <Grid container gap={2} >
            <Grid item xs={2.5} sx={{borderColor:'primary.main', borderWidth:'1px', borderRadius:'4px', p:2}}>
                <Stack>
                <Typography>Filters</Typography>
                    </Stack>

            </Grid>
            <Grid item xs>
                <Grid container gap={2} justifyContent={'center'}>
                    {seeds?.map((cardData) => {
                      return (
                        <SeedCard key={cardData.id} data={cardData} instance={instance}/>
                      )  
                    })}
                </Grid>
            </Grid>
        </Grid>
    </Box>
  </Box>
  );
};

export default Seedvault;
