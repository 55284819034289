import { signInAnonymously } from "firebase/auth";
import { auth } from "../firebase/config";
import { useAuth } from "./useAuth";
import { useState } from "react";

export const useAnon = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatchUser } = useAuth();
  const loginAnon = async (partnerAccount) => {
    setError(null);
    setIsPending(true);
    //sign the user out
    try {
      //sign in user
      //const pers = await setPersistence(auth, browserSessionPersistence)
      const res = await signInAnonymously(auth);
      console.log(res);

      if (!res) {
        throw new Error("could not complete signup");
      }
      //signed in
      setIsPending(false);
      setError(null);
      console.log(res.user);
      dispatchUser({
        type: "LOGIN",
        payload: {
          ...res.user,
          approved: false,
          partnerAccount: partnerAccount ?? null,
          privateId: partnerAccount ?? null,
        },
      });
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsPending(false);
    }
  };
  return { loginAnon, loginError: error, loginPending: isPending };
};
