import { useTheme } from "@mui/material";

export const GroupButtons = ({
  tab,
  buttonsArray,
}) => {
  const theme = useTheme();
  return (
    <div
      style={{
        width: "fit-content",
        fontSize: 14,
        fontFamily: "Messina Sans light",
        zIndex: 10,
        height: "fit-content",
        backgroundColor: "transparent",
        borderRadius: "100px",
        cursor: "pointer",
        border: `1px solid ${theme.palette.customColors.highlight}`,
        gap: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      {buttonsArray.map((button,index) => {
        return (
          <div
          key={index}
            style={{
              cursor: "pointer",
              color:
                tab === button.name
                  ? theme.palette.primary.main
                  : theme.palette.customColors.highlight,
              backgroundColor:
                tab === button.name
                  ? theme.palette.customColors.highlight
                  : "transparent",
              padding: 10,
              height: "100%",
            }}
            onClick={() => button.click(button.name)}
          >
            {button.title}
          </div>
        );
      })}
    </div>
  );
};
