// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import { getAuth } from "firebase/auth";
import {getFirestore} from "firebase/firestore"
import {getFunctions} from "firebase/functions"
import { Firestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyByVuueLDuAppf1sc0YIkB1R8Umyct47II",
    authDomain: "vtec-27b63.firebaseapp.com",
    projectId: "vtec-27b63",
    storageBucket: "vtec-27b63.appspot.com",
    messagingSenderId: "822281450914",
    appId: "1:822281450914:web:0fd3556ea9a3ca63c6aefb",
    measurementId: "G-438E4PXHZS"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app)
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const timestamp = Firestore.timestamp

export { app , auth, db, functions, timestamp}