import { useEffect, useState } from "react";
import { Typography, useTheme } from "@mui/material";
import ReactFlow, { useReactFlow } from 'reactflow';
import "reactflow/dist/style.css";
import UnsFlowNode from "./UnsFlowNode";
import { useParams } from "react-router-dom";
import UnsEdge from "./UnsEdge";

const nodeTypes = { UnsFlowNode: UnsFlowNode };

const edgeTypes = {
    unsedge: UnsEdge,
  };

const flowDataArray = [
  {
    id: "tHktnIRtcSYdmjTa0CZe",
    title: "general_manufacturing",
    description: "jfsdghgjkdshfbv",
    flow: [
      {
        id: "zerokey",
        source: "zerokey",
        img:"https://tulip.widen.net/content/6alrnecxhf/webp/zerokey%20uns%20flow.webp",
        target: "mqtt",
        topic:'zerokey',
        protocol:"MQTT",
        sourcePosition: "right",
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 0, y: 30 },
      },
      {
        id: "arduino",
        source: "arduino",
        img:"https://tulip.widen.net/content/kh2t7rdjba/webp/arduino%20uns%20flow.webp",
        target: "mqtt",
        protocol:"MQTT",
        topic:'arduino',
        sourcePosition: "right",
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 0, y: 65 },
      },
      {
        id: "banner",
        source: "banner",
        img:"https://tulip.widen.net/content/g4xipn2ujx/webp/banner%20tec%20uns.webp",
        target: "edgeNR1",
        protocol:"MODBUS",
        topic:'banner',
        sourcePosition: "right",
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 0, y: 100 },
      },
      {
        id: "mqtt",
        source: "mqtt",
        img:"https://tulip.widen.net/content/rwaecjwxio/webp/mqtt%20uns.webp",
        target: "litmus",
        targetPosition:"left",
        sourcePosition: "right",
        topic:['banner','zerokey'],
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 340, y: 65 },
      },
      {
        id: "edgeNR1",
        source: "edgeNR1",
        img:"https://tulip.widen.net/content/5hpfbwt4ln/webp/edge%20nr.webp",
        sourcePosition: "right",
        targetPosition: "left",
        topic:'banner',
        target:'mqtt',
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 170, y: 100 },
      },
      {
        id: "edgeNR2",
        source: "edgeNR2",
        img:"https://tulip.widen.net/content/5hpfbwt4ln/webp/edge%20nr.webp",
        sourcePosition: "right",
        targetPosition: "left",
        topic:'3d_printing',
        target:'mqtt',
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 170, y: 180 },
      },
      {
        id: "printer1",
        source: "printer1",
        img:"https://tulip.widen.net/content/sswkf0bepy/webp/formlabs%20uns.webp",
        target: "edgeNR2",
        topic:'stalwart',
        protocol:"REST",
        targetPosition: "left", //when im the source
        sourcePosition: "right", //when im the source
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 0, y: 160 },
      },
      {
        id: "printer2",
        source: "printer2",
        img:"https://tulip.widen.net/content/sswkf0bepy/webp/formlabs%20uns.webp",
        target: "edgeNR2",
        topic:'spryjelly',
        protocol:"REST",
        sourcePosition: "right", //when im the source
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 0, y: 200 },
      },

      {
        id: "dmgamr",
        source: "dmgamr",
        img:"https://tulip.widen.net/content/pclqwvwzdy/webp/amr%20uns.webp",
        target: "litmus",
        topic:'amr',
        protocol:"OPC UA",
        targetPosition: "left", //when im the source
        sourcePosition: "right", //when im the source
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 0, y: 255 },
      },

      {
        id: "testrack",
        source: "testrack",
        img:"https://tulip.widen.net/content/q4vyoech39/webp/celos%20x%20uns.webp",
        target: "litmus",
        topic:'celos_x',
        protocol:"OPC UA",
        targetPosition: "left", //when im the source
        sourcePosition: "right", //when im the source
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 0, y: 295 },
      }
      ,
      {
        id: "dmgmori",
        source: "dmgmori",
        img:"https://tulip.widen.net/content/jduoawt6rr/webp/dmgmori%20uns.webp",
        target: "litmus",
        topic:'dmu75',
        protocol:"OPC UA",
        targetPosition: "left", //when im the source
        sourcePosition: "right", //when im the source
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 0, y: 335 },
      }
      ,
      {
        id: "litmus",
        source: "litmus",
        img:"https://tulip.widen.net/content/lfy6o67uwb/webp/litmus%20uns.webp",
        target: "hivemq",
        topic:'machine_shop',
        sourcePosition: "right", //when im the source
        targetPosition: "left", //when im the source
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 500, y: 200 },
      },
      {
        id: "hivemq",
        source: "hivemq",
        img:"https://tulip.widen.net/content/nvbfbewak0/webp/hive%20mq%20uns.webp",
        target: "snowflake",
        topic:'general',
        sourcePosition: "top", //when im the source
        targetPosition: "left", //when im the source
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 650, y: 200 },
      },
      {
        id: "snowflake",
        source: "snowflake",
        img:"https://tulip.widen.net/content/cdsnaf0zad/webp/snowflake%20uns%20%281%29.webp",
        topic:'general',
        sourcePosition: "right", //when im the source
        targetPosition: "bottom", //when im the target
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 650, y: 60 },
      },
      {
        id: "lib",
        source: "lib",
        img:"https://tulip.widen.net/content/g9b1k2alsk/webp/tulip%20uns.webp",
        target: "automations",
        topic:'operational',
        sourcePosition: "right", //when im the source
        targetPosition: "left", //when im the source
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 0, y: 400 },
      },
      {
        id: "automations",
        source: "automations",
        img:"https://tulip.widen.net/content/bbyetodxfp/webp/automations%20uns.webp",
        target: "hivemq",
        topic:'operational',
        protocol:'Table Updates',
        sourcePosition: "right", //when im the source
        targetPosition: "left", //when im the source
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 450, y: 365 },
      },
      {
        id: "tulip",
        source: "hivemq",
        img:"https://tulip.widen.net/content/g9b1k2alsk/webp/tulip%20uns.webp",
        target: "tulip",
        topic:'general',
        sourcePosition: "top", //when im the source
        targetPosition: "top", //when im the source
        sourceOverride: "bottom",
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 650, y: 400 },
      },
    ],
  },{
    id: "kuDiqPpyVcemLYIzLJF7",
    tite: "life_sciences",
    flow: [
      {
        id: "ohausScale",
        source: "ohausScale",
        img:"https://tulip.widen.net/content/tfgvqc4civ/webp/wd%20scale%20uns.webp",
        target: "edgeNR",
        topic:'ohaus',
        protocol:"USB",
        sourcePosition: "right",
        targetPosition: "left",
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 0, y: 0 },
      },
      {
        id: "phMeter",
        source: "phMeter",
        img:"https://tulip.widen.net/content/tc1lczijgh/webp/ph%20uns.webp",
        target: "edgeNR",
        protocol:"RS232",
        sourcePosition: "right",
        targetPosition: "left",
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 0, y: 40 },
      },
      {
        id: "wave",
        source: "wave",
        img:"https://tulip.widen.net/content/mp11nmbrom/webp/wave%20uns.webp",
        target: "edgeNR",
        topic:'wave',
        protocol:"MODBUS",
        sourcePosition: "right",
        targetPosition: "left",
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 0, y: 80 },
      },
       
      {
        id: "flowhood",
        source: "flowhood",
        img:"https://tulip.widen.net/content/gmcyv5zmyi/webp/flowhood.webp",
        target: "edgeNR",
        topic:'flow',
        protocol:"XML",
        sourcePosition: "right",
        targetPosition: "left",
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 0, y: 120 },
      },
      {
        id: "fridge",
        source: "fridge",
        img:"https://tulip.widen.net/content/brypflv4sz/webp/fridge%20uns.webp",
        target: "edgeNR",
        topic:'fridge',
        protocol:"PHIDGET",
        sourcePosition: "right",
        targetPosition: "left",
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 0, y: 160 },
      },
      {
        id: "sartorius",
        source: "sartorius",
        img:"https://tulip.widen.net/content/9uzmrm9tzw/webp/sartorius%20uns.webp",
        target: "highbytehub",
        topic:'sartorius',
        protocol:"OPC UA",
        sourcePosition: "right",
        targetPosition: "left",
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 0, y: 220 },
      },
      {
        id: "optix",
        source: "optix",
        img:"https://tulip.widen.net/content/cbfy9kmhjp/webp/optix%20uns.webp",
        target: "highbytehub",
        topic:'optix',
        protocol:"OPC UA",
        sourcePosition: "right",
        targetPosition: "left",
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 0, y: 260 },
      },
      {
        id: "edgeNR",
        source: "edgeNR",
        target:'highbyte',
        img:"https://tulip.widen.net/content/5hpfbwt4ln/webp/edge%20nr.webp",
        sourcePosition: "right",
        targetPosition: "left",
        topic:['flow','ohaus','wave','ph','fridge'],
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 190, y: 75 },
      },
      {
        id: "highbyte",
        source: "highbyte",
        target:'highbytehub',
        img:"https://tulip.widen.net/content/bipepwwxx0/webp/highbyte%20uns.webp",
        sourcePosition: "right",
        targetPosition: "left",
        topic:['flow','ohaus','wave','ph','fridge'],
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 330, y: 125 },
      },
      {
        id: "highbytehub",
        source: "highbytehub",
        img:"https://tulip.widen.net/content/xz5pyhzlxu/webp/highbyte%20hub%20uns.webp",
        sourcePosition: "right",
        topic:'life',
        target:'hivemq',
        targetPosition: "left",
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 470, y: 175 },
      },
      {
        id: "hivemq",
        source: "hivemq",
        target:'snowflake',
        topic:'life',
        img:"https://tulip.widen.net/content/nvbfbewak0/webp/hive%20mq%20uns.webp",
        sourcePosition: "top", //when im the source
        targetPosition: "left", //when im the source
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 630, y: 175 },
      },
      {
        id: "lib",
        source: "lib",
        img:"https://tulip.widen.net/content/g9b1k2alsk/webp/tulip%20uns.webp",
        target: "automations",
        sourcePosition: "right", //when im the source
        targetPosition: "left", //when im the source
        topic:'operational',
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 0, y: 350 },
      },
      {
        id: "automations",
        source: "automations",
        img:"https://tulip.widen.net/content/bbyetodxfp/webp/automations%20uns.webp",
        target: "highbytehub",
        topic:'operational',
        protocol:'Table Updates',
        sourcePosition: "right", //when im the source
        targetPosition: "left", //when im the source
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 155, y: 315 },
      },
      {
        id: "tulip",
        source: "hivemq",
        img:"https://tulip.widen.net/content/g9b1k2alsk/webp/tulip%20uns.webp",
        target: "tulip",
        topic:'life',
        sourcePosition: "bottom", //when im the source
        targetPosition: "top", //when im the source
        sourceOverride: "bottom",
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 630, y: 350 },
      },
      {
        id: "snowflake",
        source: "snowflake",
        img:"https://tulip.widen.net/content/cdsnaf0zad/webp/snowflake%20uns%20%281%29.webp",
        topic:'general',
        sourcePosition: "bottom", //when im the source
        targetPosition: "bottom", //when im the target
        nodeType: "UnsFlowNode",
        active: false,
        position: { x: 630, y: 0 },
      },
    ],
  }
];

const UnsFlow = ({hotTopic, flowId}) => {
  const theme = useTheme();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [flowData, setFlowData] = useState(null);
  const { fitView } = useReactFlow();

  useEffect(() => {
    // Automatically zoom to fit the view
    fitView({ padding: 0.1, maxZoom: 2 });
  }, [fitView]);

  useEffect(() => {
    if (flowId) {
      const flow = flowDataArray.find((item) => item.id === flowId);

      if (flow) {
        const initialNodes = flow.flow.map((item) => ({
          id: item.id,
          position: item.position,
          data: {
            ...item,
            hotTopic:hotTopic,
            onClick: handleNodeClick,
          },
          type: item.nodeType || "default",
        }));
        const initialEdges = flow.flow
        .filter((item) => item.source && item.target)
        .map((item) => {
            


      const sourceItem = flow.flow.find((searchItem) => searchItem.source === item.source);
      const targetItem = flow.flow.find((searchItem) => searchItem.id === item.target);
      const isHighlighted = 
      (typeof hotTopic === 'string' && hotTopic.includes(item.topic)) || 
      (Array.isArray(item.topic) && item.topic.some(t => typeof hotTopic === 'string' && hotTopic.includes(t)));
       console.log(item.id, sourceItem.position, targetItem.position)  

          const edge = { 
            id: `e${item.source}-${item.target}`,
            source: item.source,
            target: item.target,
            animated: !isHighlighted,
            label: item.protocol,
            data: {
              topic: item.topic,
              hotTopic: hotTopic,
              targetPosition: item.target ? flow.flow.find((searchItem) => searchItem.id === item.target).targetPosition : 'left',
              sourcePosition: item.sourceOverride ? item.sourceOverride : flow.flow.find((searchItem) => searchItem.source === item.source).sourcePosition,
            },
            sourceX: flow.flow.find((searchItem) => searchItem.source === item.source).position.x,
            sourceY: flow.flow.find((searchItem) => searchItem.source === item.source).position.y,
            targetX: item.target ?? flow.flow.find((searchItem) => searchItem.id === item.target).position.x ,
            targetY: item.target ?? flow.flow.find((searchItem) => searchItem.id === item.target).position.y,
            type: "unsedge" || "default",
            style: { stroke: "#fff" }
          };
      
          // Log the edge
          console.log('Edge:', edge);
      
          return edge;
        });
      
        
    

        setFlowData(flow);
        setNodes(initialNodes);
        setEdges(initialEdges);
      }
    }
  }, [flowId, hotTopic]);

  const handleNodeClick = (id) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          node.data = { ...node.data, active: !node.data.active };
        }
        return node;
      })
    );
  };


  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: 10,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 10,
          color: "white",
          fontSize: "26px",
          fontWeight: "bold",
        }}
      >
      </div>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitView
        style={{ background: 'transparent' }}
      />
    </div>
  );
};

export default UnsFlow;