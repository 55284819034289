import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as SvgTulip } from "../svgs/tulip.svg";
import { useFetch } from "../hooks/useFetch";
import { useTheme } from "@mui/material";


const SvgFromUrl = ({ svgData, color }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: svgData }}
      style={{ height: "100%", color: color, padding: "0px" }}
    />
  );
};

export default function LogoMaster() {
  const { data } = useFetch();
  const theme = useTheme();

  return (
    <SvgIcon
      inheritViewBox
      sx={{ height: "100%", color: "customColors.white" }}
      component={data ? () => <SvgFromUrl svgData={data} color={theme.palette.customColors.white} /> : SvgTulip}
    />
  );
}
