import { Grid, Stack, Typography } from "@mui/material";
import { useApp } from "../hooks/useApp";
import React from "react";
import "./Factorymap.css";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as SvgTulip } from "../svgs/tulipwhite.svg";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FactoryAndPanel from "../pages/composable/FactoryAndPanel";
import { useState } from "react";
import Process from "./Process";
import LogoMaster from "./LogoMaster";
import { useTheme } from "@mui/material/styles";
import { PoweredByLogo } from "./Common/PoweredByLogo";

export default function FactoryContent({
  activeFactory,
  isModal,
  isFactoryDirect = false,
}) {
  const theme = useTheme();

  const style = {
    p: 0,
    width: "100%",
    borderRadius: 2,
    border: "1px solid",
    borderColor: "divider",
    backgroundColor: "#FFF",
  };

  const { setActiveFactory, selectNode, combinedAssets, svgLogo } = useApp();
  const handleClose = () => {
    setActiveFactory(null);
    selectNode(null);
  };

  const factoryData = () => {
    if (activeFactory.Suites) {
      return true;
    } else {
      let outputData = [
        {
          ...activeFactory,
          color: theme.palette.info.main,
          Children: activeFactory.Children.map((child) => {
            // Determine if child is a string (ID) or an object with an id key
            const childId = typeof child === "string" ? child : child.id;
            // Find the matching asset from combinedAssets
            let matchedAsset = combinedAssets.find(
              (vtecAsset) => vtecAsset.id === childId
            );
            if (matchedAsset) {
              // If child is an object, replace Top and Left if they exist
              if (typeof child === "object") {
                matchedAsset = {
                  ...matchedAsset,
                  Top: child.Top !== undefined ? child.Top : matchedAsset.Top,
                  Left:
                    child.Left !== undefined ? child.Left : matchedAsset.Left,
                  flow: child?.flow ? child.flow : matchedAsset.flow,
                };
              }
            }
            return matchedAsset;
          }).filter((child) => child), // Remove any undefined values
        },
      ];
      return outputData;
    }
  };
  const data = factoryData();

  const [selectedSuite, setSelectedSuite] = useState(data[0]);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const beaconData = selectedSuite.Children.sort(
    (a, b) =>
      selectedSuite.Children.indexOf(a.id) -
      selectedSuite.Children.indexOf(b.id)
  );

  const hasFlowTrue = () => {
    return beaconData.some((obj) => obj.flow === true);
  };
  return (
    <Stack
      className="main"
      direction={"column"}
      sx={{
        ...style,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        borderRadius: 0,
        border: "0px",
        backgroundColor: "",
      }}
    >
      <Grid container padding={2} alignItems={"center"}>
        <Grid item xs={12} sm={4} className="h-[45px]">
          <SvgIcon
            inheritViewBox
            sx={{ height: "45px", width: "auto", color: "customColors.white" }}
            component={svgLogo ? LogoMaster : SvgTulip}
          />
        </Grid>
        <Grid item xs={12} sm={4} textAlign={"center"}>
          <Typography variant="h5" color={"customColors.white"}>
            {activeFactory.Title}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} textAlign={"right"}>
          {isModal === true && (
            <IconButton
              onClick={handleClose}
              size="large"
              sx={{ color: "customColors.white" }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
        </Grid>
      </Grid>
      {hasFlowTrue() && (
        <Process
          beaconData={beaconData}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
        />
      )}
      <FactoryAndPanel
        selectedAsset={selectedAsset}
        setSelectedAsset={setSelectedAsset}
        selectedSuite={selectedSuite}
      />
      {isFactoryDirect && (
        <PoweredByLogo
          style={{
            position: "absolute",
            bottom: "10px",
            right: "20px",
          }}
        />
      )}
    </Stack>
  );
}
