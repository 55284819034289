import { Box, ThemeProvider, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDocs } from "../../hooks/useDocs";
import { useAuth } from "../../hooks/useAuth";
import { useApp } from "../../hooks/useApp";
import CircularProgress from "@mui/material/CircularProgress";
import { useAnon } from "../../hooks/useAnon";
import { useSegment } from "../../hooks/useSegment";
import MegaFactoryContent from "../../components/MegaFactoryContent";
import FactoryContent from "../../components/FactoryContent";
import createCustomTheme from '../../components/theme/theme'
import Videoplayer from "../../components/Videoplayer";

export default function FactoryDirect() {
  const { asset, account } = useParams();
  const [activeAccount, setActiveAccount] = useState(null);
  const [activeAsset, setActiveAsset] = useState(null);
  const { getUserDeets, getUserPrivateId } = useDocs("partners");
  const [loaded, setLoaded] = useState({
    loading: null,
    value: 0,
    fail: false,
  });
  const { user } = useAuth();
  const { activeVideo, vtecAssetsUnsorted: vtecAssets } = useApp();
  const { trackDirectEvent } = useSegment();
  const { loginAnon } = useAnon();
  const [selectedTheme, setSelectedTheme] = useState(null);
  let theme = selectedTheme ?  createCustomTheme(selectedTheme.primaryColor, selectedTheme.bgColor, selectedTheme.highlight ) : createCustomTheme("#00263e", "#E7E6DF", "#F2EEA1");
  // pull in the information meticulously checking that everything is in the correct condition

  useEffect(() => {
    debugger;
    if (!(account && asset && vtecAssets)) return;

    const getDeets = async () => {
      setLoaded({ loading: "Loading account", value: 25, fail: false });

      const gotDeets = await getUserDeets(account);
      if (!gotDeets) {
        setLoaded({
          loading: "Invalid Account",
          fail: true,
        });
        return;
      }

      if (!gotDeets.approved && !gotDeets.email.includes('@tulip.co')) {
        setLoaded({ loading: "Account not approved", fail: true });
        return;
      }

      const gotDoc = await getUserPrivateId(gotDeets.privateId);
      setActiveAccount({ ...gotDeets, ...gotDoc });
      if(gotDoc?.theme){
        const partnerTheme = gotDoc.theme;
        setSelectedTheme(partnerTheme);
        theme = createCustomTheme(partnerTheme?.primaryColor, partnerTheme?.bgColor, partnerTheme.highlight);

        const root = document.getElementsByClassName("factory-direct-root")[0];
        root?.style.setProperty('--gradient-default-nav', `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`); 
        root?.style.setProperty('--gradient-default', `linear-gradient(to left,${theme.palette.primary.main}, ${theme.palette.secondary.main})`);
        root?.style.setProperty('--gradient-default-bottom', `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`); 
        root?.style.setProperty('--radial-gradient' , `radial-gradient(circle at 50% 50%, ${theme.palette.secondary.main} 0%, transparent 90% ), ${theme.palette.primary.main}` );
        root?.style.setProperty('--text-white', `${theme.palette.customColors.white}`);
        root?.style.setProperty('--preview-bg-color', `${theme.palette.background.default}`);
      }

      setLoaded({ loading: "Loading asset", value: 50, fail: false });

      const foundAsset = vtecAssets.find((vtecAsset) => vtecAsset.id === asset);
      if (!foundAsset) {
        setLoaded({ loading: "Invalid asset ID", fail: true });
        return;
      }

      setActiveAsset(foundAsset);

      if (foundAsset.Type !== "megafactory" && foundAsset.Type !== "factory") {
        setLoaded({
          loading: "Only works for factories and megafactories",
          fail: true,
        });
        return;
      }
      setLoaded({ loading: "Compiling assets", value: 75, fail: false });
      setLoaded({ loading: "complete", value: 100, fail: false });
      if (user) {
        trackDirectEvent("asset opened", foundAsset, gotDeets.privateId);
      } else {
        loginAnon(gotDeets.privateId).then(() => {
          trackDirectEvent("asset opened", foundAsset, gotDeets.privateId);
        });
      }
    };

    getDeets();
  }, [account, asset, vtecAssets]);

  return (
    <ThemeProvider theme={theme}>
 <Box
      className={"standardGradient factory-direct-root"}
      style={{ width: "100%", backgroundColor: "grey", height: "100vh" }}
    >
      {loaded.loading !== "complete" ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <CircularProgress
            color={loaded.fail ? "error" : "success"}
            size={100}
            thickness={5}
          />
          <Typography fontSize={"large"} color={"#fff"} marginTop={4}>
            {loaded.loading}
          </Typography>
        </Box>
      ) : (
        <>
          {activeAsset && activeAsset.Type === "factory" ? (
            <FactoryContent
              activeFactory={activeAsset}
              isModal={false}
              isFactoryDirect={true}
            />
          ) : (
            <MegaFactoryContent
              activeMegaFactory={activeAsset}
              isModal={false}
              isFactoryDirect={true}
            />
          )}
          {activeVideo && <Videoplayer />}
        </>
      )}
    </Box>

    </ThemeProvider>
   
  );
}
