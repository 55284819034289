import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { useDocs } from "../hooks/useDocs";
import { useApp } from "../hooks/useApp";
import { useState } from "react";

export default function Bookmark({ asset }) {
  const { bookmarks } = useApp();
  const { setOpportunityBookmarks } = useDocs("opportunities");
  const { selectedOpp } = useApp();
  const [defaultCheck, setDefaultCheck] = useState(false);

  const handleButtonClick = (event) => {
    if (event.target.checked && selectedOpp) {
      if (bookmarks) {
        setOpportunityBookmarks(selectedOpp.id, [...bookmarks, asset.id]);
        setDefaultCheck(true);
      } else {
        setOpportunityBookmarks(selectedOpp.id, [asset.id]);
        setDefaultCheck(false);
      }
    } else {
      setOpportunityBookmarks(
        selectedOpp.id,
        bookmarks.filter((bookmark) => bookmark !== asset.id)
      );
    }
  };

  return (
    <div>
      <Checkbox
        checked={
          bookmarks && bookmarks.length > 0 && bookmarks.includes(asset.id)
        }
        sx={{
          color: "rgba(255,255,255,.5)",
          "&.Mui-checked": {
            color: "customColors.highlight",
          },
        }}
        icon={<ControlPointIcon fontSize="medium" />}
        checkedIcon={<CheckCircleIcon fontSize="medium" />}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onChange={(event) => {
          handleButtonClick(event);
        }}
      />
    </div>
  );
}
