import { Button } from "@mui/material";

export const CustomButton = ({
    variant = 'contained',
    color = 'primary',
    size = 'medium',
    onClick,
    disabled = false,
    startIcon,
    endIcon,
    children,
    ...otherProps
}) => {

  return (
    <Button
        variant={variant}
        color={color}
        size={size}
        onClick={onClick}
        disabled={disabled}
        startIcon={startIcon}
        endIcon={endIcon}
        {...otherProps}
      >
        {children}
      </Button>
  );
};
