import { Fragment } from "react";
import AssetCard from "../../components/Assetcard";
import {
  Card,
  CardMedia,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useApp } from "../../hooks/useApp";
import Gobutton from "../../components/Gobutton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import FactoryIcon from "@mui/icons-material/Factory";
import InfoIcon from "@mui/icons-material/Info";
import Videoplayer from "../../components/Videoplayer";
import { useTheme } from '@mui/material/styles';

const style = {
  p: 0,
  width: "100%",
  borderRadius: 2,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "#FFF",
  fontSize: "6px",
};

export default function Review({ asset, setAsset }) {
//   function handleChange(event) {
//     const newAsset = { ...asset, [event.target.id]: event.target.value };
//     setAsset(newAsset);
//   }

//   const handleNewFeature = () => {
//     const newFeatures = [...asset.Features, ""];
//     const newAsset = { ...asset, Features: newFeatures };
//     setAsset(newAsset);
//   };
  const { activeVideo } = useApp();
  const theme = useTheme();
  return (
    <Grid container sx={{ flexDirection: "row", gap: 4 }}>
      {activeVideo && <Videoplayer />}
      <Grid item xs={12}>
        <Typography>
          We will review your asset before it is published
        </Typography>
      </Grid>
      <Grid item xs>
        <AssetCard asset={asset} />
      </Grid>
      <Grid item xs={7} sx={{ justifyContent: "center", alignItems: "center" }}>
        <Grid
          item
          xs
          sx={{ backgroundColor: "background.default" }}
          marginY={"auto"}
          borderRadius={"0px 8px 8px 8px"}
          padding={5}
        >
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Typography color={"customColors.default"}  variant="h8">
                {asset.Title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} alignItems={"center"}>
              <Card sx={{ boxShadow: "none", backgroundColor: "transparent" }}>
                <CardMedia
                  component="img"
                  image={asset.img}
                  style={{
                    boxShadow: "none",
                    maxWidth: "100%", // Set width to 100% to fill the entire width of the Card
                    maxHeight: "80vh",
                    objectFit: "cover", // Maintain aspect ratio while covering the entire space
                    objectPosition: "center", // Center the image within the CardMedia
                  }}
                />
              </Card>
            </Grid>
            <Grid item xs>
              <Typography fontSize={"small"} color={"customColors.default"}>
                Key Features
              </Typography>
              {asset.Features && asset.Features[0] != "" && (
                <List aria-label="mailbox folders" sx={style}>
                  {asset.Features.map((feature, index) => (
                    <Fragment key={index}>
                      <ListItem>
                        <ListItemText
                          sx={{ color: "customColors.defaultLight" }}
                          primary={feature}
                        />
                      </ListItem>
                      {index < asset.Features.length - 1 && (
                        <Divider component="li" />
                      )}
                    </Fragment>
                  ))}
                </List>
              )}
            </Grid>
            <Grid
              container
              justifyContent={"right"}
              alignItems={"bottom"}
              gap={2}
            >
              {asset.appUrl && (
                <Gobutton
                  text={"Open the App"}
                  startIcon={<ExitToAppIcon />}
                  url={{ type: "beacon", url: asset.appUrl }}
                />
              )}
              {asset.editorUrl && (
                <Gobutton
                  text={"Open the Editor"}
                  startIcon={<LaptopMacIcon />}
                  url={{ type: "beacon", url: asset.editorUrl }}
                />
              )}
              {asset.contentUrl && (
                <Gobutton
                  text={"More info"}
                  startIcon={<InfoIcon />}
                  url={{ type: "content", url: asset.contentUrl }}
                />
              )}
              {asset.video && (
                <Gobutton
                  text={"Play the Video"}
                  startIcon={<InfoIcon />}
                  url={{ type: "video", url: asset.video }}
                />
              )}
              {asset.Type == "factory" && (
                <Gobutton
                  text={"Explore the Factory"}
                  startIcon={<FactoryIcon />}
                  url={"factory"}
                />
              )}
              {asset.Type == "megafactory" && (
                <Gobutton
                  text={"Explore the Factory"}
                  startIcon={<FactoryIcon />}
                  url={"megafactory"}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
