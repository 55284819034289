import React from "react";
import { Handle } from "reactflow";
import "./TableNode.css";
import { ReactComponent as SvgBool } from "../svgs/boolean.svg";
import { ReactComponent as SvgFloat } from "../svgs/float.svg";
import { ReactComponent as SvgImg } from "../svgs/imageurl.svg";
import { ReactComponent as SvgInteger } from "../svgs/integer.svg";
import { ReactComponent as SvgInterval } from "../svgs/interval.svg";
import { ReactComponent as SvgLink } from "../svgs/link.svg";
import { ReactComponent as SvgString } from "../svgs/string.svg";
import { ReactComponent as SvgTime } from "../svgs/timestamp.svg";
import { ReactComponent as SvgUser } from "../svgs/user.svg";
import { ReactComponent as SvgColor } from "../svgs/color.svg";
import { useTheme } from "@mui/material";

const handleStyle = { right: 10 };
const svgStyle = { width: "15px", marginRight: "5px" };
const getSvgComponent = (type) => {
  switch (type) {
    case "boolean":
      return <SvgBool style={svgStyle} />;
    case "color":
      return <SvgColor style={svgStyle} />;
    case "float":
      return <SvgFloat style={svgStyle} />;
    case "imageUrl":
      return <SvgImg style={svgStyle} />;
    case "integer":
      return <SvgInteger style={svgStyle} />;
    case "interval":
      return <SvgInterval style={svgStyle} />;
    case "tableLink":
      return <SvgLink style={svgStyle} />;
    case "string":
      return <SvgString style={svgStyle} />;
    case "timestamp":
      return <SvgTime style={svgStyle} />;
    case "user":
      return <SvgUser style={svgStyle} />;
    default:
      return null;
  }
};

export default function TableNode({ data, isConnectable }) {
  
  const theme = useTheme();
  const stereotypeColor = {
    Log: "#D8BEDC",
    "Operational Artifact": theme.palette.customColors.highlight,
    "Physical Artifact": "#FFBE50",
    Reference: "#C5D9E7",
  };
  return (
    <div className="tableNode">
      <div className="titlecontainer">
        {data.title}
        <div
          className="stereotype"
          style={{ "--stereocolor": stereotypeColor[data.stereotype] }}
        >
          {data.stereotype}
        </div>
      </div>

      <div className="description">{data.description}</div>

      {data.columns.map((column) => {
        console.log(data);
        console.log(
          `target-${data.id}-${column.name}  source-${data.id}-${column.name}`
        );
        return (
          <div
            className="fieldTitle"
            key={column.name}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Handle
              type="source"
              position={"left"}
              style={{ left: -5, position: "relative", opacity: 0 }}
              id={`source-${data.id}-${column.name}`}
              isConnectable={true}
            />
            {getSvgComponent(column.dataType.type)}
            <div style={{ flexGrow: 1 }}>{column.label}</div>
            <Handle
              type="target"
              position={"right"}
              style={{ right: -5, position: "relative", opacity: 0 }}
              id={`target-${data.id}-${column.name}`}
              isConnectable={true}
            />
          </div>
        );
      })}
    </div>
  );
}
