import { Box, Typography } from "@mui/material";
import { Handle, Position } from "reactflow";



const Position_Providers = {
  left: Position.Left,
  right: Position.Right,
  top: Position.Top,
  bottom: Position.Bottom
}

const CustomNode = ({ data }) => {
  return (
    <div>
      {data.targetPosition && <Handle type="target" position={data.targetPosition} />}
      <Box
        sx={{
          padding: "10px",
          border: "none",
          borderRadius: '8px',
          justifyContent: 'space-between',
          gap:'10',
          flexDirection:'column',
          display:'flex',
          backgroundColor: data?.active ? "#F2EEA1" : "#75828D",
          color: data?.active ? "#00263E" : "#FFF",
          textAlign: "center",
          cursor: "pointer",
          pointerEvents: "all",
          width: "120px"
        }}
        onClick={() => data.onClick(data.url)}
      >
        <Typography fontSize={10}>{data.title}</Typography>
        <img src={data.img}/>
        <Typography fontSize={10}>{data.description}</Typography>
      
      </Box>
      {data.sourcePosition && <Handle type="source" position={data.sourcePosition} />}
      
      
    </div>
  );
};

export default CustomNode;
