import { useEffect, } from "react";
import createCustomTheme from './theme'
import {ThemeProvider} from "@mui/material";
import Vtec from "../../pages/vtec/Vtec";
import './PreviewTheme.css'

export default function PreviewTheme({ partnerTheme }){
  let theme = createCustomTheme(partnerTheme?.primaryColor, partnerTheme?.bgColor, partnerTheme.highlight);

  useEffect(() => {
    theme = createCustomTheme(partnerTheme?.primaryColor, partnerTheme?.bgColor, partnerTheme.highlight);

    const root = document.getElementsByClassName("preview-theme")[0];
    root?.style.setProperty('--gradient-default-nav', `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`); 
    root?.style.setProperty('--gradient-default', `linear-gradient(to left,${theme.palette.primary.main}, ${theme.palette.secondary.main})`);
    root?.style.setProperty('--gradient-default-bottom', `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`); 
    root?.style.setProperty('--radial-gradient' , `radial-gradient(circle at 50% 50%, ${theme.palette.secondary.main} 0%, transparent 90% ), ${theme.palette.primary.main}` );
    root?.style.setProperty('--text-white', `${theme.palette.customColors.white}`);
    root?.style.setProperty('--preview-bg-color', `${theme.palette.background.default}`);
  },[partnerTheme]);
    return (
        <ThemeProvider theme={theme}>
          <div className="preview-theme">
            <Vtec isPreview={true} />
          </div>
        </ThemeProvider>
        
    );
}