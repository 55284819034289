import { functions } from "../firebase/config";
import { httpsCallable } from "firebase/functions";
import { useApp } from "./useApp";
import { useAuth } from "./useAuth";

export const useSendgrid = () => {
  const { vtecAssets, bookmarks } = useApp();
  const { user } = useAuth();

  const filteredAssets =
    vtecAssets &&
    vtecAssets.filter((asset) => {
      if (
        bookmarks &&
        vtecAssets &&
        bookmarks.length > 0 &&
        vtecAssets.length > 0
      ) {
        return bookmarks.includes(asset.id);
      } else {
        return [];
      }
    });

  const prepData = async () => {
    const htmlString = filteredAssets
      .map((asset) => {
        let linksString = "";
        if (asset.contentUrl && asset.contentUrl.trim() !== "") {
          linksString += `<a href='${asset.contentUrl}'>Content</a><br>`;
        }
        if (asset.video && asset.video.trim() !== "") {
          linksString += `<a href='${asset.video}'>Video</a><br>`;
        }

        return `<tr><td width='200px'><img src='${asset.Image}' width='200px'/></td><td width='200px'>${asset.Title}</td><td>${asset.Heading}<p>${linksString}</td></tr>`;
      })
      .join("");
    const res = await httpsCallable(
      functions,
      "sendGridEmail"
    )({ asset_data: htmlString });
    console.log(res);
    console.log("done");
  };

  return { prepData };
};
