import {
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
  Button,
  Checkbox,
} from "@mui/material";
import FactoryDrag from "./FactoryDrag";
import { useApp } from "../../hooks/useApp";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Tooltip from '@mui/material/Tooltip';

export default function FactoryThree({ asset, setAsset }) {
  const { combinedAssets } = useApp();
  const [dropDown, setDropDown] = useState(null);
  function handleChange(event) {
    const newAsset = { ...asset, [event.target.id]: event.target.value };
    setAsset(newAsset);
  }
  function handleAdd() {
    if (dropDown) {
      //theres something selected, thats good
      if (asset.Children.some((child) => child.id === dropDown.id)) {
        return;
      }
      const newChild = {
        id: dropDown.id,
        Title: dropDown.Title,
        Left: "50%",
        Top: "50%",
        flow: false,
      };
      const newChildren = [...asset.Children, newChild];
      setAsset((prevAsset) => ({ ...prevAsset, Children: newChildren }));
    }
  }

  const moveAsset = (index, direction) => {
    const newIndex = direction === "up" ? index - 1 : index + 1;
    if (newIndex >= 0 && newIndex < asset.Children.length) {
      const newChildren = [...asset.Children];
      const [movedItem] = newChildren.splice(index, 1);
      newChildren.splice(newIndex, 0, movedItem);
      setAsset((prevAsset) => ({ ...prevAsset, Children: newChildren }));
    }
  };

  return (
    <Grid container sx={{ flexDirection: "row", gap: 4 }}>
      <Grid
        item
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: "fit-content",
        }}
        xs={12}
        xl={8}
      >
        <FactoryDrag asset={asset} setAsset={setAsset} />
      </Grid>
      <Grid item xs>
        <Stack direction={"column"} gap={1} sx={{ height: "100%" }}>
          <Typography fontWeight={"bold"}>Factory Details</Typography>

          <TextField
            id="Title"
            label="Title"
            fullWidth
            size="small"
            value={asset.Title}
            onChange={(event) => {
              handleChange(event);
            }}
          />
          <TextField
            id="img"
            label="Image"
            fullWidth
            size="small"
            value={asset.img}
            onChange={(event) => {
              handleChange(event);
            }}
          />
          <Typography>Assets</Typography>
          <Stack direction={"row"}>
            {combinedAssets && (
              <Autocomplete
                disablePortal
                id="factorySelect"
                options={combinedAssets.filter(
                  (asset) =>
                    asset.Type &&
                    ["app", "beacon", "video"].includes(asset.Type)
                )}
                getOptionLabel={(option) => option.Title} // Specify which property to use as the label
                sx={{ width: "100%" }}
                size="small"
                value={dropDown}
                onChange={(event, newValue) => {
                  setDropDown(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Title" />
                )}
              />
            )}
            <Button
              onClick={() => {
                handleAdd();
              }}
            >
              Add
            </Button>
          </Stack>
          <Box
            sx={{
              border: "1px solid #BDBDBD",
              height: "100%",
              paddingBlock: 2,
              paddingInline: 1,
              borderRadius: 2,
            }}
          >
              {asset.Children &&
                asset.Children.length > 0 &&
                asset.Children.map((child, thisIndex) => {
                  return (
                    <Stack
                      key={thisIndex}
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      marginBottom={1}
                      borderBottom={1}
                      borderColor={"#BDBDBD"}
                    >
                      <div className="flex">
                        <Typography>{child.Title}</Typography>
                      </div>

                      <div className="flex">
                        {child.flow && (
                          <>
                            <IconButton
                              aria-label="down"
                              size="small"
                              onClick={() => moveAsset(thisIndex, "down")}
                              disabled={thisIndex === asset.Children.length - 1}
                            >
                              <ArrowDownwardIcon fontSize={"small"}/>
                            </IconButton>
                            <IconButton
                              aria-label="up"
                              size="small"
                              onClick={() => moveAsset(thisIndex, "up")}
                              disabled={thisIndex === 0}
                            >
                              <ArrowUpwardIcon fontSize={"small"} />
                            </IconButton>
                          </>
                        )}
                        <Tooltip title="Flow" placement="left">
                          <Checkbox
                            checked={child.flow || false}
                            name="flow"
                            onClick={(e) => {
                              setAsset((prevAsset) => {
                                const newSuites = [...prevAsset.Children];
                                newSuites[thisIndex] = {
                                  ...newSuites[thisIndex],
                                  flow: e.target.checked,
                                };
                                return { ...prevAsset, Children: newSuites };
                              });
                            }}
                          />
                        </Tooltip>

                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            const newChildren = asset.Children.filter(
                              (item, index) => index !== thisIndex
                            );
                            setAsset({ ...asset, Children: newChildren });
                          }}
                        >
                          <DeleteIcon fontSize={"small"} />
                        </IconButton>
                      </div>
                    </Stack>
                  );
                })}
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}
