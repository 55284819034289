import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AppProvider } from "./context/AppContext";
import { AuthProvider } from "./context/AuthContext";
import { createTheme } from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));
// window.eventemitter = new Event();

root.render(
  <React.StrictMode>
    <AppProvider>
      <AuthProvider>
          <App />
      </AuthProvider>
    </AppProvider>
  </React.StrictMode>
);
