import { Box, Typography } from "@mui/material";
import { Handle, Position } from "reactflow";



const Position_Providers = {
  left: Position.Left,
  right: Position.Right,
  top: Position.Top,
  bottom: Position.Bottom
}

const UnsFlowNode = ({ data }) => {
    
    const isHighlighted = data.hotTopic && ( 
        // Check if data.topic is a string and if it's in hotTopic
        (typeof data.topic === 'string' && data.hotTopic.includes(data.topic)) ||
        // Or check if data.topic is an array and if any item is in hotTopic
        (Array.isArray(data.topic) && data.topic.some(t => data.hotTopic.includes(t)))
      ) ? true : false;
  
    return (
    <div>
      {data.targetPosition && <Handle type="target" position={data.targetPosition} style={{opacity:0}} />}
      <Box
        sx={{
          borderColor: !data.hotTopic?"transparent":(data.hotTopic.includes(data.topic)?'#F2EEA1':'transparent'),
          borderWidth: '2px',
          borderRadius: '3px',
          boxSizing: "border-box",
          color: data?.active ? "white" : "black",
          textAlign: "center",
          cursor: "pointer",
          pointerEvents: "all",
          height: "30px",
        }}
        onClick={() => data.onClick(data.id)}
      >
        <img src={data.img} style={{height:'100%'}}/>
      </Box>
      {data.sourcePosition && <Handle type="source" position={data.sourcePosition} style={{opacity:0}} />}
      
      
    </div>
  );
};

export default UnsFlowNode;