import React, { useEffect } from 'react';


// Component that copies custom MIME type data to clipboard
const Copy = () => {


  return (
    <div>
      <h3>Checking if you are allowed...</h3>
    </div>
  );
};

export default Copy;
