import React, { Fragment, useEffect, useState } from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as TulipIcon } from "../../svgs/tulipwhite.svg";
import "./Login.css";
import { Typography, Button, Box, Stack, Paper } from "@mui/material";
import { useGoogle } from "../../hooks/useGoogle";
import { Grid, Divider } from "@mui/material";
import SignupComponent from "./SignupComponent";
import PartnerLoginComponent from "./PartnerLoginComponent";
import { useAuth } from "../../hooks/useAuth";
import { useLogout } from "../../hooks/useLogout";
import ResetPassword from "./ResetPassword";

//pages and components
export default function Login() {
  const { loginGoogle, isPendingGoogle, errorGoogle } = useGoogle();
  const [partnerMode, setPartnerMode] = useState(false);
  const [signupFlag, setSignupFlag] = useState(false);
  const [resetMode, setResetMode] = useState(false);
  const { user } = useAuth();
  const { logout } = useLogout();

  const handleGoogle = () => {
    const { errorGoogle } = loginGoogle();
  };

  return (
    <Grid container sx={{ height: "100vh", width: "100%" }}>
      <Grid
        item
        sm={7}
        xs={12}
        sx={{
          bgcolor: "primary.main",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <SvgIcon
          inheritViewBox
          component={TulipIcon}
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            height: "auto",
            width: "150px",
            color: "#FFF",
            padding: 12,
          }}
        />
        <img
          src="https://tulip.widen.net/content/6luriwjyb9/webp/COMPRESSED-Illustration-Day-in-the-Life-General-Manufacturing.webp"
          style={{ maxWidth: "100%", maxHeight: "100vh" }}
        ></img>
      </Grid>
      <Grid
        sm={5}
        xs={12}
        item
        component={Paper}
        sx={{
          bgcolor: "background.default",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          padding: 4,
        }}
      >
        <Stack
          direction={"column"}
          gap={2}
          sx={{ width: "100%", padding: 5, borderRadius: 4 }}
          component={Paper}
          elevation={4}
        >
          {user && !user.emailVerified && !user.approved ? (
            <Stack gap={4}>
              <Typography textAlign={"center"}>
                Please verify your Email Address
              </Typography>
              <Button
                variant="contained"
                onClick={logout}
                sx={{
                  backgroundColor: "secondary.main",
                  "&:hover": {
                    backgroundColor: "info.main",
                  },
                }}
              >
                Logout
              </Button>
            </Stack>
          ) : user && !user.approved ? (
            <Stack gap={4}>
              <Typography textAlign={"center"}>
                We have your request and are processing it
              </Typography>
              <Button
                variant="contained"
                onClick={logout}
                sx={{
                  backgroundColor: "secondary.main",
                  "&:hover": {
                    backgroundColor: "info.main",
                  },
                }}
              >
                Logout
              </Button>
            </Stack>
          ) : partnerMode ? (
            !signupFlag ? (
              resetMode ? (
                <>
                  <ResetPassword setResetMode={setResetMode}/>
                </>
              ) : (
                <PartnerLoginComponent
                  setPartnerMode={setPartnerMode}
                  setSignupFlag={setSignupFlag}
                  setResetMode={setResetMode}
                />
              )
            ) : (
              <SignupComponent setSignupFlag={setSignupFlag} />
            )
          ) : (
            <Fragment>
              <Typography fontSize={"large"}>Virtual TEC Login</Typography>
              <Button
                variant="contained"
                onClick={handleGoogle}
                sx={{
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "secondary.main",
                  },
                }}
              >
                Tulip Login
              </Button>
              {errorGoogle && (
                <Typography color={"red"}>{errorGoogle}</Typography>
              )}
              <Divider>
                <Typography sx={{ fontSize: 12 }}>OR</Typography>
              </Divider>
              <Button
                variant="contained"
                onClick={() => {
                  setPartnerMode(true);
                }}
                sx={{
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "secondary.main",
                  },
                }}
              >
                Partner Login
              </Button>
            </Fragment>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}
