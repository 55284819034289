import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { useApp } from "../hooks/useApp";

export default function Gobutton({ text, startIcon, url, color }) {
  const {
    setOppyState,
    setActiveFactory,
    setActiveMegaFactory,
    selectedAsset,
    setActiveVideo,
  } = useApp();
  const ColorButton = styled(Button)(({ theme }) => ({
    color: "secondary",
    backgroundColor:
      color && color.bg ? color.bg : theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.info.main,
    },
  }));

  const handleButtonClick = () => {
    if (url.type === "beacon") {
      window.open(url.url, "_blank");
    } else if (url === "oppy") {
      setOppyState((prev) => !prev);
    } else if (url === "factory") {
      setActiveFactory(selectedAsset);
    } else if (url === "megafactory") {
      setActiveMegaFactory(selectedAsset);
    } else if (url.type && url.type === "video") {
      setActiveVideo(url.url);
    } else if (url.type && url.type === "content") {
      window.open(url.url, "_blank");
    }
  };
  return (
    <Grid item>
      <ColorButton
        variant="contained"
        startIcon={startIcon}
        onClick={handleButtonClick}
      >
        {text}
      </ColorButton>
    </Grid>
  );
}
