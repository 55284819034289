import { forceSimulation, forceManyBody, forceX, forceY } from "d3-force";
import { useCdm } from "../../hooks/useCdm";
import { useEffect } from "react";

export const simulation = forceSimulation()
  .force("charge", forceManyBody().strength(-1000))
  .force("x", forceX().x(0).strength(0.05))
  .force("y", forceY().y(0).strength(0.05))
  .alphaTarget(0.05)
  .stop();

export const useGetData = () => {
  const { data, loading, error } = useCdm();
  useEffect(() => {
    if (data && !loading && !error) {
      // Perform operations on the data once it's available
      //let uniqueStereotypes = [...new Set(data[0].Data.map(obj => obj.stereotype))];
      let uniqueStereotypes = [
        "Physical Artifact",
        "Process Artifact",
        "Reference",
        "Log",
      ];
      let dataNodes = data[0].Data.map((table, index) => ({
        id: table.id,
        data: table,
        position: { x: 100, y: 100 },
        type: "tableNode",
      }));
      return { data, dataNodes };
    }
  }, [data, loading, error]);
};

export const hexToRgb = (hex, alpha) => {
  // Remove the leading # if present
  hex = hex.replace(/^#/, "");

  // Parse the hex value into its components
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Return the RGB color as an object
  return `rgba(${r}, ${g}, ${b}, ${alpha})`; // Fixed missing closing parenthesis
};
//this checks to see if the string is basically the same as the other string for drawing lines
export const isSubstring = (table, column) => {
  if (column.includes(table.slice(0, -1))) {
    return true;
  }
  return false;
};

// export const stereotypeColor = {
//   Log: "#D8BEDC",
//   "Process Artifact": "#F2EEA1",
//   "Physical Artifact": "#FFBE50",
//   Reference: "#C5D9E7",
// };

export function removeDuplicateIds(array) {
  const uniqueIds = new Set();
  return array.filter((obj) => {
    if (!uniqueIds.has(obj.id)) {
      uniqueIds.add(obj.id);
      return true;
    }
    return false;
  });
}

export const foreignKeys = (data) => {
  return data[0].Data.map((table) => {
    //only include the columns that link out
    return {
      ...table,
      columns: table.columns.filter(
        (column) => column.label.includes("ID") && column.name !== "id"
      ),
    };
  })
    .filter((table) => table.columns.length > 0)
    .map((table) => {
      //build a new dataset that looks for matching tables
      return {
        ...table,
        columns: table.columns.map((column) => {
          //check if there is a match on the column
          return {
            ...column,
            ref: data[0].Data.filter((table) =>
              isSubstring(table.title, column.label)
            )[0]
              ? data[0].Data.filter((table) =>
                  isSubstring(table.title, column.label)
                )[0].id
              : null,
          };
        }),
      };
    })
    .flatMap((table) => {
      //build the actual line list now
      return table.columns.map((column) => {
        return {
          id: `${table.id}-${column.ref}`,
          source: table.id,
          target: column.ref,
        };
      });
    })
    .filter((line) => line.source && line.target);
};

export const makeLayout = (data) => {
  // Step 1: Group objects based on stereotype
  const groupedByStereotype = data.reduce((acc, obj) => {
    const stereotype = obj.data.label.match(/\(([^)]+)\)/)[1]; // Extract stereotype from label
    if (!acc[stereotype]) {
      acc[stereotype] = [];
    }
    acc[stereotype].push(obj);
    return acc;
  }, {});

  // Step 2: Sort each group based on width to find the widest element
  const sortedGroups = Object.values(groupedByStereotype).map((group) => {
    return group.sort((a, b) => {
      // Assuming the width of each item is stored in a 'width' property
      return b.width - a.width; // Sort in descending order of width
    });
  });

  // Step 3: Calculate positions for each group
  let currentY = 0;
  const positions = sortedGroups.map((group) => {
    const groupWidth = group[0].width;
    const groupHeight = group.reduce((acc, obj) => acc + obj.height, 0);
    const groupX = 0; // Start all groups at x = 0

    const groupPosition = { x: groupX, y: currentY };
    currentY += groupHeight + 50; // Move to the next group position

    return {
      group,
      position: groupPosition,
      width: groupWidth,
      height: groupHeight,
    };
  });

  // Step 4: Update positions of each item within each group
  const updatedData = positions.flatMap(({ group, position }) => {
    let currentX = position.x;
    let currentY = position.y;
    return group.map((obj) => {
      // Calculate position for each object within the group
      const newPosition = { x: currentX, y: currentY };
      currentY += obj.height + 50; // Move to the next item position
      return { ...obj, position: newPosition };
    });
  });

  return updatedData;
};
