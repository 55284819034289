import FactoryContent from "../../components/FactoryContent";
import MegaFactoryContent from "../../components/MegaFactoryContent";

export default function FactoryPreview({ asset }) {
  return (
    <div className="preview-mega-factory">
      {asset && asset.Type === "megafactory" ? (
        <MegaFactoryContent activeMegaFactory={asset} isModal={false} />
      ) : (
        <FactoryContent activeFactory={asset} isModal={false} />
      )}
    </div>
  );
}
