import { Modal } from "@mui/material";
import { useApp } from "../hooks/useApp";
import React from "react";
import "./Factorymap.css";
import FactoryContent from "./FactoryContent";

export default function Factorymodal() {
  const { activeFactory, setActiveFactory, selectNode } = useApp();
  const handleClose = () => {
    setActiveFactory(null);
    selectNode(null);
  };
  return (
    <React.Fragment>
      <Modal
        open={activeFactory}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <FactoryContent activeFactory={activeFactory} isModal={true}/>
      </Modal>
    </React.Fragment>
  );
}
