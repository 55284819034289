import { useCallback } from 'react';

export const useCopy = () => {
  const triggerCopy = useCallback((dataToCopy) => {
    // Format your data as a string or JSON for copying
    const textData = JSON.stringify(dataToCopy);

    // Use the Clipboard API to copy text
    navigator.clipboard.writeText(textData)
      .then(() => {
        console.log('Copied to clipboard:', textData);
      })
      .catch((err) => {
        console.error('Failed to copy text to clipboard', err);
      });
  }, []);

  return { triggerCopy };
};
