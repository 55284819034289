import { useReducer, useEffect, useState } from "react";
import { db } from "../firebase/config";
import {
  collection,
  doc,
  addDoc,
  setDoc,
  deleteDoc,
  onSnapshot,
  getDoc,
  writeBatch,
  serverTimestamp,
  query,
  where,
  getDocs
} from "firebase/firestore";

let initialState = {
  document: null,
  isPending: false,
  error: null,
  success: null,
};

const firestoreReducer = (state, action) => {
  switch (action.type) {
    case "IS_PENDING":
      return { document: null, success: null, error: null, isPending: true };
    case "ADDED_DOCUMENT":
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        document: null,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const useDocs = (collectionid) => {
  const [response, dispatch] = useReducer(firestoreReducer, initialState);
  const [isCancelled, setIsCancelled] = useState(false);
  const [mydoc, setMydoc] = useState(null);
  const [error, setError] = useState(null);
  const [mydocPending, setMydocPending] = useState(false);

  const dispatchIfNotCancelled = (action) => {
    if (!isCancelled) {
      dispatch(action);
    }
  };

  const deleteAsset = async (asset) => {
    try {
      const documentRef = doc(db, "vtec_assets", asset.id);
      await deleteDoc(documentRef);
      console.log("deleted");
    } catch (err) {
      console.log(err.message);
    }
  };

  const addDocument = async (newdoc, user) => {
    dispatch({ type: "IS_PENDING" });
    try {
      const addedDocument = await addDoc(collection(db, "vtec_assets"), {
        ...newdoc,
        userid: user.uid,
        email: user.email,
        createdAt: serverTimestamp()
      });

      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
      console.log(addedDocument);
      return addedDocument;
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.log(err.message);
      return null;
    }
  };

  const addSeed = async (newdoc, user, meta) => {
    dispatch({ type: "IS_PENDING" });
    try {
      const addedDocument = await addDoc(collection(db, "seeds"), {
        seedData: newdoc,
        userid: user.uid,
        email: user.email,
        title: meta.title,
        desc: meta.desc,
        imgurl: meta.imgurl,
        createdAt: serverTimestamp()
      });

      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
      console.log(addedDocument);
      return addedDocument;
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.log(err.message);
      return null;
    }
  };

  const updatePosition = async (asset) => {
    dispatch({ type: "IS_PENDING" }); // Assuming you're using Redux
    try {
      const addedDocument = await setDoc(
        doc(db, "vtec_assets", asset.id),
        { Top: asset.Top, Left: asset.Left },
        { merge: true }
      );
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      }); // Assuming you're using Redux
      console.log("Document updated successfully");
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message }); // Assuming you're using Redux
      console.error("Failed to update document:", err);
    }
  };

  const updateAsset = async (asset) => {
    dispatch({ type: "IS_PENDING" }); // Assuming you're using Redux
    try {
      const { id, ...rest } = asset;
      const addedDocument = await setDoc(
        doc(db, "vtec_assets", asset.id),
        rest,
        { merge: true }
      );
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      }); // Assuming you're using Redux
      console.log("Document updated successfully");
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message }); // Assuming you're using Redux
      console.error("Failed to update document:", err);
    }
  };

  const setDocument = async (vtecAsset, data) => {
    dispatch({ type: "IS_PENDING" });
    console.log(vtecAsset.id);
    try {
      const addedDocument = await setDoc(
        doc(db, "vtec_assets", vtecAsset.id),
        data,
        { merge: true }
      );

      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
      console.log("done");
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.log(`failed to write ${err}`);
    }
  };

  const setOpportunity = async (opportunityId, notes) => {
    dispatch({ type: "IS_PENDING" });
    console.log("setting the opportunity");
    try {
      const addedDocument = await setDoc(
        doc(db, "opportunities", opportunityId),
        { notes: notes },
        { merge: true }
      );
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
      console.log("done");
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.log(`failed to write ${err}`);
    }
  };

  const setOpportunityBookmarks = async (opportunityId, bookmarks) => {
    dispatch({ type: "IS_PENDING" });
    console.log("setting the opportunity bookmarks");
    try {
      const addedDocument = await setDoc(
        doc(db, "opportunities", opportunityId),
        { bookmarks: bookmarks },
        { merge: true }
      );
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
      console.log("done w bookmarks");
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.log(`failed to write ${err}`);
    }
  };

  

  const getUserPrivateId = async (privateId) => {
    try {
      let ref = doc(db, "opportunities", privateId);
      const docSnap = await getDoc(ref);
      console.log(docSnap);
      return docSnap.data();
    } catch (err) {
      console.log(err, privateId);
      return { svg: null };
    }
  };

  const getUserDeets = async (userId) => {
    try {
      let ref = doc(db, "partners", userId);
      const docSnap = await getDoc(ref);
      console.log(docSnap);
      return docSnap.data();
    } catch (err) {
      return { approved: false, partnerAccount: null, privateId: null };
    }
  };
  const setUserDeets = async (userId, email, emailVerified) => {
    dispatch({ type: "IS_PENDING" });
    console.log("setting the user");
    console.log(userId);
    try {
      const addedDocument = await setDoc(
        doc(db, "partners", userId),
        {
          approved: false,
          partnerAccount: null,
          email: email,
          privateId: null,
          emailVerified: emailVerified
        },
        { merge: true }
      );
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
      console.log("done");
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.log(`failed to write ${err}`);
    }
  };

  const deletePartner = async (partner) => {
    try {
      const documentRef = doc(db, "partners", partner.id);
      await deleteDoc(documentRef);
      console.log("deleted");
    } catch (err) {
      console.log(err.message);
    }
  };

  const deleteSeed = async (seed) => {
    try {
      const documentRef = doc(db, "seeds", seed.id);
      await deleteDoc(documentRef);
      console.log("deleted");
    } catch (err) {
      console.log(err.message);
    }
  };


  const ReadDocument = (collectionId, docId) => {
    //fires once and whenever the collection changes
    useEffect(() => {
      setMydocPending(true);
      //set up a real time listener to a firestore collection
      let ref = doc(db, collectionid, docId);
      const unsubscribe = onSnapshot(
        ref,
        (snapshot) => {
          if (snapshot.exists()) {
            console.log("exists");
            setMydoc(snapshot.data());
          } else {
            console.log("doc doesnt exist");
            setOpportunity(docId, null, []);
          }
          setMydocPending(false);
          setError(null);
        },
        (error) => {
          console.log(error);
          setMydocPending(false);
          setError("could not fetch the doc");
        }
      );
      //unsub on unmount
      return () => unsubscribe();
    }, [collectionId, docId]);
    return { mydoc, error, mydocPending };
  };
  //for updating partner accounts and parterns
  const updateDocument = async (id, obj, collectionType) => {
    dispatch({ type: "IS_PENDING" });
    try {
      const addedDocument = await setDoc(doc(db, collectionType, id), obj, {
        merge: true,
      });
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.error(err); // Log the entire error object for better debugging
    }
  };
  const updateMultipleDocuments = async (updates) => {
    const batch = writeBatch(db); // Initialize the batch
    // Iterate over the updates array and add each update to the batch
    updates.forEach(({ collection, id, data }) => {
      const docRef = doc(db, collection, id);
      batch.update(docRef, data);
    });
    dispatch({ type: "IS_PENDING" });
    try {
      await batch.commit(); // Commit the batch
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: updates, // You can customize the payload as needed
      });
      console.log("Documents updated successfully");
    } catch (error) {
      dispatchIfNotCancelled({
        type: "ERROR",
        payload: error.message,
      });
      console.error("Error updating documents: ", error);
    }
  };

  const setEmailVerified = async (userId, emailVerified) => {
    dispatch({ type: "IS_PENDING" });
    try {
      const addedDocument = await setDoc(
        doc(db, "partners", userId),
        {
          emailVerified: emailVerified
        },
        { merge: true }
      );
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
      console.log("done");
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.log(`failed to write ${err}`);
    }
  };
  const addViewDocument = async (newdoc) => {
    debugger
    const {id, ...rest } = newdoc;
    dispatch({ type: "IS_PENDING" });
    try {
      const collectionRef = collection(db, 'views');
      if(newdoc.isAdminDefault){
        const q = query(collectionRef, where('isAdminDefault', '==', true));
        const querySnapshot = await getDocs(q);

        const batch = writeBatch(db);
        querySnapshot.forEach((doc) => {
          const docRef = doc.ref;
          if (doc.id !== id) { // Exclude the document we're currently saving or updating
            batch.update(docRef, { isAdminDefault: false });
          }
        });
        await batch.commit();
      }
      const docRef = id 
      ? doc(db, "views", id) 
      : collectionRef;

      const addedDocument = id 
      ? await setDoc(docRef, { ...rest, createdAt: serverTimestamp() }) 
      : await addDoc(docRef, { ...rest, createdAt: serverTimestamp() });
      
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
      console.log(addedDocument);
      return addedDocument;
      
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.log(err.message);
      return null;
    }
  };
  const deleteDocument = async (id, collection) => {
    try {
      const documentRef = doc(db, collection, id);
      await deleteDoc(documentRef);
      console.log("deleted");
    } catch (err) {
      console.log(err.message);
    }
  };
  const addViewsToPartner = async (data, userId) => {
    dispatch({ type: "IS_PENDING" });
    try {
      const addedDocument = await setDoc(
        doc(db, "partners", userId),data,
        { merge: true }
      );
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
      console.log("done");
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.log(`failed to write ${err}`);
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, [mydoc]);

  return {
    addDocument,
    deleteDocument,
    deletePartner,
    setDocument,
    updateAsset,
    deleteAsset,
    getUserPrivateId,
    response,
    ReadDocument,
    getUserDeets,
    setUserDeets,
    setOpportunity,
    mydoc,
    deleteSeed,
    addSeed,
    setOpportunityBookmarks,
    updatePosition,
    updateDocument,
    updateMultipleDocuments,
    setEmailVerified,
    addViewDocument,
    addViewsToPartner
  };
};
