import { useState } from "react";
import { useSignup } from "../../hooks/useSignup";
import TextField from "@mui/material/TextField";
import { Box, Link, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Button } from "@mui/material";
import { useLogin } from "../../hooks/useLogin";

//pages and components
export default function PartnerLoginComponent({
  setPartnerMode,
  setSignupFlag,
  setResetMode
}) {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const { signup, isPending, error } = useSignup();
  const { loginEmail, loginError, loginPending } = useLogin();

  const handleSubmit = () => {
    //signup(email, password)
    console.log(email);
    if (email && password) {
      console.log("running login");
      loginEmail(email, password);
    }
  };

  const handleInput = (event) => {
    const { value, id } = event.target;
    switch (id) {
      case "email":
        setEmail(value);
        return;
      case "password":
        setPassword(value);
        return;
    }
  };
  const handleForgotPassword = () => setResetMode(true);

  return (
    <Stack gap={2}>
      <Typography variant="h6">Partner Login</Typography>
      <TextField
        id="email"
        label="Email"
        value={email}
        onChange={handleInput}
        autoComplete="current-email"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="password"
        type="password"
        label="Password"
        value={password}
        onChange={handleInput}
        autoComplete="current-password"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Link
        component="button"
        variant="body2"
        underline="none"
        textAlign={"right"}
        onClick={handleForgotPassword}
      >
        Forgot Password?
      </Link>
      <Stack
        direction={"row"}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          xs
          style={{ justifySelf: "flex-start" }}
          sx={{ color: "primary.main" }}
          onClick={() => {
            setPartnerMode(false);
          }}
        >
          Back
        </Button>
        <Box style={{ width: "fit-content" }}>
          <Button
            onClick={() => {
              setSignupFlag(true);
            }}
            sx={{ color: "primary.main" }}
          >
            Signup
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "secondary.main",
              },
            }}
          >
            Login
          </Button>
        </Box>
      </Stack>
      {loginError && (
        <Typography>
          There was an issue with your login information, you may need to sign
          up
        </Typography>
      )}
    </Stack>
  );
}
